import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { useUnmount } from '@packlink/utils';
import { ApiClientError, DisclaimerManagementRepository, IDisclaimerManagement, IParcel } from '@packlink/packlink-sdk';

import { logSdkError } from '@utils/logger';
import { apiClient } from '@sdk';

const QUERY_KEY_WEIGHT_DEVIATION = 'weight-deviation';
interface IUseWeightDeviation {
    data?: IDisclaimerManagement;
    isLoading: boolean;
    isError: boolean;
}
export const useWeightDeviation = (
    alpha2Code: string | null,
    parcels: Partial<IParcel>[],
    isDisclaimerActive: boolean,
    onSuccessCallback: () => void,
): IUseWeightDeviation => {
    const queryClient = useQueryClient();
    const disclaimerManagementRepository = new DisclaimerManagementRepository(apiClient);

    const prepareParcels = parcels.map((parcel: Partial<IParcel>) => ({
        weight: parcel.weight || 0,
        length: parcel.length || 0,
        width: parcel.width || 0,
        height: parcel.height || 0,
    }));

    const parcelsKey = useMemo(
        () =>
            parcels.reduce((acc: string, current: Partial<IParcel>) => {
                if (current.weight && current.height && current.length && current.width) {
                    acc += `${current.weight}-${current.length}-${current.width}-${current.height}/`;
                }
                return acc;
            }, ''),

        [parcels],
    );

    const { data, isError, isFetching } = useQuery(
        [QUERY_KEY_WEIGHT_DEVIATION, parcelsKey, alpha2Code],
        () => {
            return parcels && alpha2Code
                ? disclaimerManagementRepository.getWeightDeviation({
                      toCountry: alpha2Code,
                      packages: prepareParcels,
                  })
                : { showDisclaimer: false };
        },
        {
            enabled: !!alpha2Code && !!parcelsKey && isDisclaimerActive,
            staleTime: Infinity,
            onSuccess: (data: IDisclaimerManagement) => {
                data.showDisclaimer && onSuccessCallback();
            },
            onError: (error: ApiClientError) => {
                logSdkError(error);
            },
        },
    );

    useUnmount(() => {
        queryClient.cancelQueries(QUERY_KEY_WEIGHT_DEVIATION);
    });

    return {
        data,
        isError,
        isLoading: isFetching,
    };
};
