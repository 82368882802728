import { QueryStatus, useQuery } from 'react-query';
import { MutableRefObject, useRef, useEffect } from 'react';

import { useIntersection } from '@packlink/utils';

export type UseRequestOnIntersectionProps<T> = {
    /** Array with cache keys, requestData will be triggered whenever this array changes */
    requestCacheKeys: unknown[];
    /** The time in milliseconds after data is considered stale */
    staleTime?: number;
    /** Function that returns a promise with the data needed for the chart */
    requestData: () => Promise<T>;
    /** This callback will fire any time the request is either successfully fetched or errors and be passed either the data or error. */
    onFetch?: (data: T | undefined) => void;
};

interface IUseRequestOnIntersection<T> {
    data: T | undefined;
    status: QueryStatus;
    isFetching: boolean;
    intersectionRef: MutableRefObject<null>;
}

export function useRequestOnIntersection<T>({
    requestCacheKeys,
    staleTime = 3600000, // 1 hour
    requestData,
    onFetch,
}: UseRequestOnIntersectionProps<T>): IUseRequestOnIntersection<T> {
    const intersectionRef = useRef(null);
    const intersection = useIntersection(intersectionRef, {
        threshold: 0.1,
    });

    const { data, status, isFetching } = useQuery(requestCacheKeys, () => requestData(), {
        enabled: intersection?.isIntersecting || false,
        staleTime,
    });

    useEffect(() => {
        data && onFetch?.(data);
    }, [data, onFetch]);

    return {
        data,
        status,
        isFetching,
        intersectionRef,
    };
}
