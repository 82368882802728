import { PropsWithChildren, useState } from 'react';
import { useQuery } from 'react-query';
import { useSdk } from '@packlink/packlink-sdk-provider';
import { IExperiences, PersonalizedExperiencesRepository } from '@packlink/packlink-sdk';
import * as Sentry from '@sentry/core';

import {
    PersonalizedExperienceContext,
    PERSONAL_EXPERIENCE_CACHE_KEY,
    IPersonalizedExperienceCategory,
} from './PersonalizedExperienceContext';

export interface IPersonalizedExperienceProvider {
    categories?: IPersonalizedExperienceCategory[];
}

export const PersonalizedExperienceProvider = ({
    children,
    categories: defaultCategories,
}: PropsWithChildren<IPersonalizedExperienceProvider>): JSX.Element => {
    const [categories, setCategories] = useState<IPersonalizedExperienceCategory[]>(defaultCategories || []);
    const sdk = useSdk();
    const personalizedExperienceRepository = new PersonalizedExperiencesRepository(sdk.apiClient);

    const { data: experiences } = useQuery<IExperiences[]>(
        [PERSONAL_EXPERIENCE_CACHE_KEY],
        () => personalizedExperienceRepository.getAll(),
        {
            refetchOnMount: false,
            staleTime: 150,
            onError: (error) => {
                Sentry.captureException(error);
            },
        },
    );

    return (
        <PersonalizedExperienceContext.Provider
            value={{ experiences: experiences || [], categories: categories || [], setCategories }}
        >
            {children}
        </PersonalizedExperienceContext.Provider>
    );
};
