import { useState } from 'react';
import { useNavigate } from 'react-router';
import { APP_ROUTE } from '@router/routes';
import { useTranslation } from '@packlink/translation-provider';
import { SubscriptionPlan } from '@packlink/packlink-sdk';
import { SidePanel, SlideInPosition } from '@shipengine/giger';
import { PanelName } from '@types';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { SubscriptionPanelContent } from './SubscriptionPanelContent';

interface SubscriptionPanelProps {
    planDetails?: SubscriptionPlan;
    showSuccessAction?: boolean;
}

export const SubscriptionPanel = ({ planDetails, showSuccessAction }: SubscriptionPanelProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeSidebarClickEvent } = useAmplitude();
    const { close, isOpen } = useSidePanel(PanelName.SUBSCRIPTIONS);
    const navigate = useNavigate();
    const [navigateToPro, setNavigateToPro] = useState(false);

    const closeAndNavigate = () => {
        close();
        navigateToPro && navigate(APP_ROUTE.SHIPMENTS.INBOX_READY_TO_PURCHASE);
    };

    const handleClose = () => {
        sendAmplitudeSidebarClickEvent(AmplitudeEvents.X, {
            [AmplitudeProperties.PANEL_NAME]: PanelName.SUBSCRIPTIONS,
        });
        closeAndNavigate();
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            containerId="shipments-main"
            applyBackdropToBody={false}
            onBackdropClick={closeAndNavigate}
            header={<SidePanelHeader onAction={handleClose} title={t('subscriptions.side-panel.header-title')} />}
        >
            {isOpen && planDetails && (
                <SubscriptionPanelContent
                    planDetails={planDetails}
                    close={closeAndNavigate}
                    goToProOnSuccess={showSuccessAction ? () => setNavigateToPro(true) : undefined}
                />
            )}
        </SidePanel>
    );
};
