import {
    Address,
    CashOnDelivery,
    IAddress,
    IContent,
    IInterval,
    IParcel,
    IPostalCode,
    IPostalZone,
    IPrice,
    IShipment,
    IVoucher,
    Parcel,
    Service,
    ShipmentThirdPartyPreferencesProps,
    Voucher,
} from '@packlink/packlink-sdk';
import {
    DELETE_VOUCHER,
    RESET_CHECKOUT,
    RESET_CHECKOUT_SERVICE,
    SET_ADDRESS_BOOK_CHECKED,
    SET_CHECKOUT_ADDITIONAL_DATA,
    SET_CHECKOUT_ADDRESS,
    SET_CHECKOUT_ADDRESS_COUNTRY,
    SET_CHECKOUT_ADDRESS_DETAILS,
    SET_CHECKOUT_ADDRESS_POSTALCODE,
    SET_CHECKOUT_ADULT_SIGNATURE_VALUE,
    SET_CHECKOUT_BUSY,
    SET_CHECKOUT_COLLECTION_DATE,
    SET_CHECKOUT_COLLECTION_TIME,
    SET_CHECKOUT_CONTENT,
    SET_CHECKOUT_DROPOFF_POINT_ID,
    SET_CHECKOUT_INSURANCE_TYPE,
    SET_CHECKOUT_PARCELS,
    SET_CHECKOUT_PRICE_DETAILS,
    SET_CHECKOUT_SELECTED_SHIPMENT,
    SET_CHECKOUT_SELECTED_WAREHOUSE,
    SET_CHECKOUT_SERVICE,
    SET_CHECKOUT_VOUCHER,
    SET_CHECKOUT_WAREHOUSE_ADDRESS,
    SET_INVOICE_INFO_SAVED,
    SET_CHECKOUT_THIRD_PARTY_PREFERENCES,
    SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE,
    SET_CHECKOUT_CASH_ON_DELIVERY,
    SET_CHECKOUT_PROOF_OF_DELIVERY,
} from '@store/actions/action-types';
import { CheckoutAction, CheckoutAddressDetails, CheckoutAddressOrigin } from '@store/actions/checkout';
import {
    checkoutAdditionalData,
    checkoutAddressAlpha2Code,
    checkoutAddressCity,
    checkoutAddressCompany,
    checkoutAddressEmail,
    checkoutAddressFirstName,
    checkoutAddressId,
    checkoutAddressLastName,
    checkoutAddressPhone,
    checkoutAddressState,
    checkoutAddressStreet1,
    checkoutAddressStreet2,
    checkoutAddressZipCode,
    checkoutAdultSignatureType,
    checkoutCollectionDate,
    checkoutCollectionTime,
    checkoutContent,
    checkoutDropoffPointId,
    checkoutFrom,
    checkoutInsuranceType,
    checkoutParcels,
    checkoutPriceDetails,
    checkoutSelectedShipment,
    checkoutThirdPartyPreferences,
    checkoutService,
    checkoutTo,
    checkoutAdditionalHandling,
    checkoutProofOfDelivery,
} from '@store/selectors/checkout';
import { UpsellInsuranceType } from '@types';
import { pipe } from 'fp-ts/lib/function';
import { Lens } from 'monocle-ts';
import { DeepPartial } from 'redux';

export type PartialAddress = DeepPartial<IAddress>;

export interface ICheckoutState {
    readonly selectedShipment?: DeepPartial<IShipment>;
    parcels: DeepPartial<IParcel>[];
    service?: Service;
    from: PartialAddress;
    to: PartialAddress;
    dropoffPointId?: string;
    collectionDate?: string;
    collectionTime?: IInterval;
    priceDetails?: IPrice;
    insuranceType?: UpsellInsuranceType;
    adultSignature?: boolean;
    additionalHandling?: boolean;
    additionalData: Record<string, unknown>;
    content?: Partial<IContent>;
    voucher?: IVoucher;
    addressBookChecked: boolean;
    invoiceInfoSaved?: boolean;
    isBusy: boolean;
    thirdPartyPreferences?: ShipmentThirdPartyPreferencesProps;
    cashOnDelivery?: CashOnDelivery;
    proofOfDelivery?: boolean;
}

export const initialState: ICheckoutState = {
    selectedShipment: undefined,
    parcels: [],
    service: undefined,
    from: {},
    to: {},
    additionalData: {},
    dropoffPointId: undefined,
    collectionDate: undefined,
    collectionTime: undefined,
    priceDetails: undefined,
    insuranceType: undefined,
    content: undefined,
    voucher: undefined,
    addressBookChecked: false,
    invoiceInfoSaved: undefined,
    isBusy: false,
};

const getAddressLens = (origin: CheckoutAddressOrigin): Lens<ICheckoutState, PartialAddress> =>
    origin === 'from' ? checkoutFrom : checkoutTo;

const getAdditionalDataEntryLens = (attribute: string): Lens<ICheckoutState, unknown> =>
    checkoutAdditionalData.compose(Lens.fromProp<Record<string, unknown>>()(attribute));

const setAddressDetails = (
    details: CheckoutAddressDetails,
    origin: CheckoutAddressOrigin,
): ((state: ICheckoutState) => ICheckoutState) => {
    const address = getAddressLens(origin);

    return (state: ICheckoutState): ICheckoutState => {
        return pipe(
            address.compose(checkoutAddressFirstName).set(details.firstName ?? '')(state),
            address.compose(checkoutAddressLastName).set(details.lastName ?? ''),
            address.compose(checkoutAddressCompany).set(details.company ?? ''),
            address.compose(checkoutAddressStreet1).set(details.street1 ?? ''),
            address.compose(checkoutAddressStreet2).set(details.street2 ?? ''),
            address.compose(checkoutAddressEmail).set(details.email ?? ''),
            address.compose(checkoutAddressPhone).set(details.phone ?? ''),
            address.compose(checkoutAddressId).set(details.id ?? ''),
        );
    };
};

const setAddressCountry = (
    postalZone: DeepPartial<IPostalZone> | undefined,
    origin: CheckoutAddressOrigin,
): ((state: ICheckoutState) => ICheckoutState) => {
    const address = getAddressLens(origin);

    return (state: ICheckoutState): ICheckoutState => {
        return postalZone
            ? pipe(
                  // This is currently needed as the update could be coming from postalZone
                  // API or warehouse models, which currently diverge a bit
                  address.compose(checkoutAddressAlpha2Code).set(postalZone.alpha2Code as string)(state),
                  address.compose(checkoutAddressState).set(postalZone.name),
                  getAdditionalDataEntryLens(`postal_zone_id_${origin}`).set(postalZone?.id),
                  getAdditionalDataEntryLens(`postal_zone_name_${origin}`).set(postalZone?.name),
              )
            : state;
    };
};

const setAddressPostalCode = (
    postalCode: DeepPartial<IPostalCode>,
    origin: CheckoutAddressOrigin,
): ((state: ICheckoutState) => ICheckoutState) => {
    const address = getAddressLens(origin);

    return (state: ICheckoutState): ICheckoutState => {
        return pipe(
            address.compose(checkoutAddressZipCode).set(postalCode.zipCode as string)(state),
            address.compose(checkoutAddressCity).set(postalCode.city as string),
            getAdditionalDataEntryLens(`zip_code_id_${origin}`).set(postalCode.id),
        );
    };
};

const setSelectedWarehouseId =
    (id: string | undefined) =>
    (state: ICheckoutState): ICheckoutState => {
        return getAdditionalDataEntryLens('selectedWarehouseId').set(id)(state);
    };

export const reducer = (state: ICheckoutState = initialState, action: CheckoutAction): ICheckoutState => {
    switch (action.type) {
        case SET_CHECKOUT_PARCELS:
            return pipe(
                checkoutParcels.set(action.payload.map((parcel: Parcel): DeepPartial<IParcel> => parcel.toJSON()))(
                    state,
                ),
                getAdditionalDataEntryLens('parcelIds').set(
                    action.payload.map((parcel: Parcel): string | undefined => parcel.id).filter(Boolean),
                ),
            );
        case SET_CHECKOUT_ADDRESS_DETAILS: {
            const { addressDetails, origin } = action.payload;
            return setAddressDetails(addressDetails, origin)(state);
        }
        case SET_CHECKOUT_ADDRESS_COUNTRY: {
            const { postalZone, origin } = action.payload;
            return setAddressCountry(postalZone, origin)(state);
        }
        case SET_CHECKOUT_ADDRESS_POSTALCODE: {
            const { postalCode, origin } = action.payload;
            return setAddressPostalCode(postalCode, origin)(state);
        }
        case SET_CHECKOUT_SELECTED_WAREHOUSE: {
            const warehouseId = action.payload;
            return setSelectedWarehouseId(warehouseId)(state);
        }
        case SET_CHECKOUT_WAREHOUSE_ADDRESS: {
            const { warehouse, locale } = action.payload;

            const address = new Address(
                undefined,
                undefined,
                undefined,
                undefined,
                warehouse?.company,
                warehouse?.email,
                warehouse?.firstName,
                warehouse?.lastName,
                warehouse?.phone,
                warehouse?.address,
                undefined,
                undefined,
            );

            const postalZone = {
                alpha2Code: warehouse?.alpha2Code,
                hasPostalCodes: undefined,
                id: warehouse?.postalZone.id,
                name: warehouse?.postalZone.translations[locale],
            };

            const postalCode = {
                city: warehouse?.city,
                id: warehouse?.postalCodeId,
                state: warehouse?.postalZone.translations[locale],
                text: undefined,
                zipCode: warehouse?.zipCode,
            };

            return pipe(
                setSelectedWarehouseId(warehouse?.id)(state),
                setAddressDetails(address, 'from'),
                setAddressCountry(postalZone, 'from'),
                setAddressPostalCode(postalCode, 'from'),
            );
        }
        case SET_CHECKOUT_ADDRESS: {
            const { address, origin } = action.payload;

            return getAddressLens(origin).set(address)(state);
        }
        case SET_CHECKOUT_ADDITIONAL_DATA: {
            const { additionalData } = action.payload;

            return checkoutAdditionalData.set(additionalData)(state);
        }
        case SET_CHECKOUT_THIRD_PARTY_PREFERENCES: {
            const { thirdPartyPreferences } = action.payload;

            return checkoutThirdPartyPreferences.set(thirdPartyPreferences)(state);
        }
        case SET_CHECKOUT_DROPOFF_POINT_ID: {
            return checkoutDropoffPointId.set(action.payload)(state);
        }
        case SET_CHECKOUT_COLLECTION_DATE: {
            return checkoutCollectionDate.set(action.payload)(state);
        }
        case SET_CHECKOUT_COLLECTION_TIME: {
            return checkoutCollectionTime.set(action.payload)(state);
        }
        case SET_CHECKOUT_PRICE_DETAILS: {
            return checkoutPriceDetails.set(action.payload)(state);
        }
        case SET_CHECKOUT_INSURANCE_TYPE: {
            return checkoutInsuranceType.set(action.payload)(state);
        }
        case SET_CHECKOUT_ADULT_SIGNATURE_VALUE: {
            return checkoutAdultSignatureType.set(action.payload)(state);
        }
        case SET_CHECKOUT_PROOF_OF_DELIVERY: {
            return checkoutProofOfDelivery.set(action.payload)(state);
        }
        case SET_CHECKOUT_ADDITIONAL_HANDLING_VALUE: {
            return checkoutAdditionalHandling.set(action.payload)(state);
        }
        case SET_CHECKOUT_SERVICE: {
            return checkoutService.set(action.payload)(state);
        }
        case SET_CHECKOUT_CONTENT: {
            return checkoutContent.set(action.payload.toJSON())(state);
        }
        case RESET_CHECKOUT_SERVICE: {
            return checkoutService.set(undefined)(state);
        }
        case SET_CHECKOUT_SELECTED_SHIPMENT: {
            // Using 'as' because sdk DeepPartial is not compatible with DeepPartial redux
            // and sdk type is not exported.
            return checkoutSelectedShipment.set(action.payload.toJSON() as DeepPartial<IShipment>)(state);
        }
        case RESET_CHECKOUT: {
            return initialState;
        }
        case SET_CHECKOUT_VOUCHER: {
            const voucher = action.payload as Voucher<IVoucher>;
            return { ...state, voucher: voucher ? voucher.toJSON() : undefined };
        }
        case DELETE_VOUCHER: {
            return { ...state, voucher: undefined };
        }
        case SET_ADDRESS_BOOK_CHECKED: {
            return { ...state, addressBookChecked: action.payload };
        }
        case SET_INVOICE_INFO_SAVED: {
            const invoiceInfoSaved = action.payload;
            return { ...state, invoiceInfoSaved };
        }
        case SET_CHECKOUT_BUSY: {
            const isBusy = action.payload;
            return { ...state, isBusy };
        }
        case SET_CHECKOUT_CASH_ON_DELIVERY: {
            const cashOnDelivery = action.payload;
            return { ...state, cashOnDelivery };
        }
        default:
            return state;
    }
};
