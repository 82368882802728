import { AmazonActivateCompleteFlow } from '@Integrations/components/LandingFlows/Amazon/AmazonActivateCompleteFlow';
import { AmazonActivateStartFlow } from '@Integrations/components/LandingFlows/Amazon/AmazonActivateStartFlow';
import { EbayActivateCompleteFlow } from '@Integrations/components/LandingFlows/Ebay/EbayActivateCompleteFlow';
import { EbayActivateStartFlow } from '@Integrations/components/LandingFlows/Ebay/EbayActivateStartFlow';
import { EtsyActivateCompleteFlow } from '@Integrations/components/LandingFlows/Etsy/EtsyActivateCompleteFlow';
import { EtsyActivateStartFlow } from '@Integrations/components/LandingFlows/Etsy/EtsyActivateStartFlow';
import { OAuthDummyActivateStartFlow } from '@Integrations/components/LandingFlows/OAuthDummy/OAuthDummyActivateStartFlow';
import { OAuthDummyActivateCompleteFlow } from '@Integrations/components/LandingFlows/OAuthDummy/OAuthDummyActivateCompleteFlow';

import { Navigate, Route, Routes } from 'react-router';
import { IntegrationRoute } from './routes';

export function IntegrationsRouter(): JSX.Element {
    return (
        <Routes>
            <Route path=":integrationAlias" element={<Navigate to="activate/start-flow" replace />} />

            <Route path={IntegrationRoute.AMAZON_ACTIVATE_START_FLOW} element={<AmazonActivateStartFlow />} />
            <Route path={IntegrationRoute.EBAY_ACTIVATE_START_FLOW} element={<EbayActivateStartFlow />} />
            <Route path={IntegrationRoute.ETSY_ACTIVATE_START_FLOW} element={<EtsyActivateStartFlow />} />
            <Route
                path={IntegrationRoute.SHIPENGINE_OAUTH_DUMMY_ACTIVATE_START_FLOW}
                element={<OAuthDummyActivateStartFlow />}
            />

            <Route path={IntegrationRoute.AMAZON_ACTIVATE_COMPLETE_FLOW} element={<AmazonActivateCompleteFlow />} />
            <Route path={IntegrationRoute.EBAY_ACTIVATE_COMPLETE_FLOW} element={<EbayActivateCompleteFlow />} />
            <Route path={IntegrationRoute.ETSY_ACTIVATE_COMPLETE_FLOW} element={<EtsyActivateCompleteFlow />} />
            <Route
                path={IntegrationRoute.SHIPENGINE_OAUTH_DUMMY_ACTIVATE_COMPLETE_FLOW}
                element={<OAuthDummyActivateCompleteFlow />}
            />
        </Routes>
    );
}
