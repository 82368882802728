import { InlineNotification, Link, NotificationType, WithCommonProps } from '@shipengine/giger';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { getSupportTicketRequirementListStyles } from './SupportTicketRequirementsStyles';

type DamageRequirementsProps = WithCommonProps<unknown>;

export function DamageRequirements(props: DamageRequirementsProps): JSX.Element {
    const { t } = useTranslation();
    const getHelpCenterPage = useHelpCenter();
    return (
        <InlineNotification
            type={NotificationType.INFO}
            title={t('support-center.support-ticket-requirements.damage-ticket-title')}
            {...props}
        >
            <Trans
                i18nKey="support-center.support-ticket-requirements.damage-ticket-requirements"
                components={{
                    ul: <ul css={getSupportTicketRequirementListStyles} />,
                    li: <li />,
                    packaging: (
                        <Link href={getHelpCenterPage(HELP_CENTER_KEYS.damagedParcel)} target="_blank" isExternal />
                    ),
                }}
            />
        </InlineNotification>
    );
}
