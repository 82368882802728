import { createContext } from 'react';

import { IExperiences } from '@packlink/packlink-sdk';

export interface IPersonalizedExperienceCategory {
    name: string;
    complete?: boolean;
    showOnce?: boolean;
}

// Provide the personalized experience context to the application.
export interface IPersonalizedExperienceContext {
    experiences: IExperiences[];
    categories: IPersonalizedExperienceCategory[];
    setCategories?: (categories: IPersonalizedExperienceCategory[]) => void;
}

export const PersonalizedExperienceContext = createContext<IPersonalizedExperienceContext>({
    experiences: [],
    categories: [],
});

export const PERSONAL_EXPERIENCE_CACHE_KEY = 'personalized-experiences';
