import { useTranslation } from '@packlink/translation-provider';
import { BottomSheet, Typography } from '@shipengine/giger';
import { getShipmentSummaryHeadingStyles } from './BottomBarStyles';

interface IBottomBarProps {
    children: React.ReactNode;
}

export const BottomBar = ({ children }: IBottomBarProps) => {
    const { t } = useTranslation();

    return (
        <aside>
            <BottomSheet opened={false}>
                <>
                    <Typography component="h5" variant="subtitle2" css={getShipmentSummaryHeadingStyles}>
                        {t('checkout.sidebar.summary.title')}
                    </Typography>
                    {children}
                </>
            </BottomSheet>
        </aside>
    );
};
