import { Experiment } from '@amplitude/experiment-js-client';
import { useQuery } from 'react-query';

export function useAmplitudeExperiment(flagKey: string) {
    const experiment = Experiment.initializeWithAmplitudeAnalytics(config.amplitudeExperiments.deploymentKey);
    const { data, isLoading } = useQuery({
        queryKey: ['experiment', flagKey],
        queryFn: () =>
            experiment.fetch().then(() => {
                return experiment.variant(flagKey).value;
            }),
    });
    return { data, isLoading };
}
