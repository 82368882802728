import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getSupportWidgetStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            position: 'absolute',
            bottom: theme.spacing(2),
            left: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(),
        });

export const getSupportWidgetButtonStyles =
    () =>
    (theme: Theme): SerializedStyles =>
        css({
            ...theme.getElevation('overlay1'),
            height: 'fit-content',
            textAlign: 'center',
        });
