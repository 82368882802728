import { IPersonalizedExperienceCategory } from './providers/PersonalizedExperienceContext';

export enum PersonalizedExperienceCategory {
    ONBOARDING = 'onboarding',
}

export const PERSONALIZED_EXPERIENCE_CATEGORIES: IPersonalizedExperienceCategory[] = [
    {
        name: PersonalizedExperienceCategory.ONBOARDING,
        showOnce: true,
    },
];
