import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';

import { AmplitudeEvents, AmplitudeEventsPro } from '@constants/amplitude';
import { GTMActionFieldOption } from '@constants/gtm';
import { useSidePanel } from '@hooks/useSidePanel';
import { useAmplitude } from '@hooks/useAmplitude';
import { OAuthDummyIntegrationPanel } from '@Integrations/components/Integrations/OAuthDummy/OAuthDummyIntegrationPanel';

import { DummyMarketplaceIntegrationPanel } from '@Integrations/components/Integrations/DummyMarketplace/DummyMarketplaceIntegrationPanel';
import { DiscogsIntegrationPanel } from '@Integrations/components/Integrations/Discogs/DiscogsIntegrationPanel';
import { AliexpressIntegrationPanel } from '@Integrations/components/Integrations/Aliexpress/AliexpressIntegrationPanel';
import { AmazonIntegrationPanel } from '@Integrations/components/Integrations/Amazon/AmazonIntegrationPanel';
import { EbayIntegrationPanel } from '@Integrations/components/Integrations/Ebay/EbayIntegrationPanel';
import { EtsyIntegrationPanel } from '@Integrations/components/Integrations/Etsy/EtsyIntegrationPanel';
import { FrontendConfiguredIntegrationPanel } from '@Integrations/components/Integrations/FrontendConfigured/FrontendConfiguredIntegrationPanel';
import { ModuleIntegrationPanel } from '@Integrations/components/Integrations/Module/ModuleIntegrationPanel';
import { EcommerceAlias, Ecommerces } from '@Integrations/constants/ecommerce';
import { useDeactivate } from '@Integrations/hooks/useDeactivate';
import { setActiveIntegrationImport } from '@Integrations/store/actions/integrations';
import { SidePanel } from '@shipengine/giger';
import { GTMEvents } from '@packlink/metrics';
import {
    ApiClientError,
    Configuration,
    ConfigurationRepository,
    ImportSource,
    Integration,
    IntegrationType,
} from '@packlink/packlink-sdk';
import { APP_ROUTE } from '@pages/router/routes';
import { apiClient } from '@sdk';
import { AppDispatch } from '@store';
import { PanelName } from '@types';
import { logSdkError } from '@utils/logger';

import { OnboardingPartnerIntegrationPanel } from '../Integrations/OnboardingPartner/OnboardingPartnerIntegrationPanel';
import { WizishopIntegrationPanel } from '../Integrations/Wizishop/WizishopIntegrationPanel';
import { getIntegrationPanelStyles } from './IntegrationPanelStyles';
import { IIntegrationPanelProps, IntegrationConfigurations } from './types';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { GET_ALL_CONFIGURATIONS_QUERY_KEY } from '@common/hooks/useIntegrations';

const configurationRepository = new ConfigurationRepository(apiClient);

export const IntegrationPanel: FC<IIntegrationPanelProps> = (props: IIntegrationPanelProps): JSX.Element => {
    const queryClient = useQueryClient();
    const sidePanel = useSidePanel(PanelName.INTEGRATIONS);
    const onboardingIntegrations = [
        EcommerceAlias.Amazon,
        EcommerceAlias.Ebay,
        EcommerceAlias.Etsy,
        EcommerceAlias.OAuthDummy,
    ];
    const dispatch = useDispatch<AppDispatch>();
    const { onClose, integration, showError, setShowError } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { deactivateIntegration } = useDeactivate(AmplitudeEventsPro.CONFIGURATION_DISCONNECT_STORE);
    const navigate = useNavigate();
    const { sendGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeEvent, sendSuffixedAmplitudeEvent, sendAmplitudeClickEvent } = useAmplitude();

    const getPanel = (integration: Integration<IntegrationConfigurations>) => {
        const integrationPanels = {
            [Ecommerces.Aliexpress]: AliexpressIntegrationPanel,
            [Ecommerces.Amazon]: AmazonIntegrationPanel,
            [Ecommerces.Ebay]: EbayIntegrationPanel,
            [Ecommerces.Etsy]: EtsyIntegrationPanel,
            [Ecommerces.Wizishop]: WizishopIntegrationPanel,
            [Ecommerces.DummyMarketplace]: DummyMarketplaceIntegrationPanel,
            [Ecommerces.Discogs]: DiscogsIntegrationPanel,
            [Ecommerces.OAuthDummy]: OAuthDummyIntegrationPanel,
            [IntegrationType.FRONTEND_CONFIGURED]: FrontendConfiguredIntegrationPanel,
            [IntegrationType.MODULE]: ModuleIntegrationPanel,
            [IntegrationType.ONBOARDING_PARTNER]: OnboardingPartnerIntegrationPanel,
            [IntegrationType.ONBOARDING_PACKLINK]: undefined,
        };
        return integrationPanels[integration?.name as Ecommerces] || integrationPanels[integration?.type];
    };

    const IntegrationPanelContent = getPanel(integration);

    const clearError = useCallback((): void => {
        setShowError(undefined);
    }, [setShowError]);

    const closePanel = useCallback((): void => {
        sidePanel.close();
        onClose();
        clearError();
    }, [onClose, sidePanel, clearError]);

    const handleSubmit = (integration: Integration<IntegrationConfigurations>): Promise<void> => {
        setIsSubmitting(true);

        sendSuffixedAmplitudeEvent(
            sendAmplitudeClickEvent,
            integration.name,
        )(AmplitudeEventsPro.CONFIGURATION_CONNECT_STORE);

        sendGtmEvent(GTMEvents.MARKETPLACE_INTEGRATION, {
            action: GTMActionFieldOption.INTEGRATION_INTEREST,
            integrationType: integration.name,
        });

        if (onboardingIntegrations.includes(integration.alias as EcommerceAlias)) {
            startOnboarding(integration, integration.configuration);
        } else if (integration.configuration) {
            return createConfiguration(integration.configuration, integration);
        }

        return new Promise((): void[] => []);
    };

    const onCancel = (integrationName: string): void => {
        sendSuffixedAmplitudeEvent(
            sendAmplitudeClickEvent,
            integrationName,
        )(AmplitudeEventsPro.CANCEL_INTEGRATION_SIDE_PANEL);
        closePanel();
    };

    const startOnboarding = (
        integration: Integration<IntegrationConfigurations>,
        configuration?: IntegrationConfigurations,
    ): void => {
        navigate(`/integrations/${integration.alias}/activate/start-flow`, {
            state: {
                data: configuration?.data,
            },
        });
    };

    const createConfiguration = (
        configuration: Configuration,
        { name, alias, configuration: integrationConfig }: Integration<IntegrationConfigurations>,
    ): Promise<void> => {
        return configurationRepository
            .createConfiguration(configuration)
            .then(async (response: { identifier: string }) => {
                configuration.identifier = response.identifier;
                dispatch(
                    setActiveIntegrationImport({
                        name,
                        alias,
                        configuration: integrationConfig,
                        source: ImportSource.NEW_INTEGRATION,
                    }),
                );
                await queryClient.invalidateQueries([GET_ALL_CONFIGURATIONS_QUERY_KEY]);
                sendAmplitudeEvent(AmplitudeEvents.INTEGRATION_AUTO_IMPORT_MODAL);
                navigate(APP_ROUTE.SHIPMENTS.INBOX_ALL);
            })
            .catch((error: ApiClientError) => {
                setShowError(true);
                logSdkError(error);
                setIsSubmitting(false);
            });
    };

    const onDeactivate = (selectedIntegration: Integration<IntegrationConfigurations>): Promise<string> => {
        return deactivateIntegration(selectedIntegration.alias, selectedIntegration.configuration?.identifier)
            .then(async () => {
                await queryClient.invalidateQueries([GET_ALL_CONFIGURATIONS_QUERY_KEY]);
                return selectedIntegration.name;
            })
            .catch((error: ApiClientError) => {
                setShowError(true);
                logSdkError(error);
                return '';
            });
    };

    return (
        <SidePanel
            data-id="side-panel"
            isOpen={sidePanel.isOpen}
            css={getIntegrationPanelStyles}
            containerId="settings-panel-container"
            onBackdropClick={() => onCancel(integration.name)}
        >
            {IntegrationPanelContent && (
                <IntegrationPanelContent
                    onClose={() => onCancel(integration.name)}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    integration={integration as any}
                    onDeactivate={onDeactivate}
                    isLoading={isSubmitting}
                    onSubmit={handleSubmit}
                    showError={showError}
                    clearError={clearError}
                    onCancel={onCancel}
                />
            )}
        </SidePanel>
    );
};
