import { Divider, Link, Typography } from '@shipengine/giger';
import { Trans } from '@packlink/translation-provider';
import { getDividerStyles } from './TemplateFooterStyles';

export function TemplateFooter(): JSX.Element {
    return (
        <>
            <Divider css={getDividerStyles} />
            <Typography component="p" variant="body1">
                <Trans i18nKey="branded-emails.template.footer" components={[<Link key="link" href="#" />]} />
            </Typography>
        </>
    );
}
