import React, { useState } from 'react';

import Packlink from '@sdk';
import { UpsellInsuranceType } from '@types';
import { ProductOptionsInsurances } from '@utils/UpsellUtils';
import { useEffectOnce } from '@packlink/utils';
import { AmplitudeTypeOfLocations } from '@packlink/metrics';
import { useTranslation } from '@packlink/translation-provider';
import { InsuranceSelector } from '@packlink/insurance-selector';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';
import { AmplitudeEvents, AmplitudeProperties, FLOW_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import { Button, ButtonSize, ButtonVariant, DialogActions, DialogContent, Link, Typography } from '@shipengine/giger';

import {
    getActionsStyles,
    getInsuranceInfoStyles,
    getInsuranceSelectorSectionStyles,
} from './InsuranceCSVModalContentStyles';
import { InsuranceResponseCSVModal } from './InsuranceResponseCSVModal';
import { useAmplitude } from '@hooks/useAmplitude';

export interface InsuranceCSVModalContentProps {
    shipmentReferences: Array<string>;
    setTitleDialog: (title: string) => void;
    onClose: () => void;
}

export const InsuranceCSVModalContent = ({
    shipmentReferences,
    setTitleDialog,
    onClose,
}: InsuranceCSVModalContentProps): JSX.Element => {
    const { t } = useTranslation();
    const { sendAmplitudeEvent } = useAmplitude();
    const [insuranceValue, setInsuranceValue] = useState<UpsellInsuranceType | undefined>();
    const [hasConfirm, setHasConfirm] = useState<boolean>();
    const [error, setError] = useState<boolean>(false);
    const getHelpCenterPage = useHelpCenter();
    const numberOfShipments = shipmentReferences.length ?? 0;

    useEffectOnce(() => {
        setTitleDialog(
            t('import-csv.success.header', {
                numberOfShipments,
            }),
        );
    });

    const insurancesToDisplay: ProductOptionsInsurances = {
        [UpsellInsuranceType.PACKLINK_INSURANCE]: {},
        [UpsellInsuranceType.NO_INSURANCE]: {},
    };

    const onChangeInsurance = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>): void => {
        setInsuranceValue(value as UpsellInsuranceType);
        sendAmplitudeEvent(AmplitudeEvents.CLICK_ON_INSURANCE, {
            [AmplitudeProperties.INSURANCE]: value,
            [AmplitudeProperties.FLOW]: FLOW_AMPLITUDE_PROPERTY.CSV_IMPORT,
            [AmplitudeProperties.LOCATION]: AmplitudeTypeOfLocations.CSV_IMPORT,
        });
    };

    const onConfirm = (): void => {
        setHasConfirm(true);
        setTitleDialog(t('import-csv.insurance.title-protecting', { numberOfShipments }));
        if (insuranceValue === UpsellInsuranceType.PACKLINK_INSURANCE) {
            const shipmentsToInsure: Record<string, boolean> = {};
            shipmentReferences.forEach((ref) => {
                if (!shipmentsToInsure[ref]) {
                    shipmentsToInsure[ref] = true;
                }
            });

            Packlink.v1.shipments.insureShipments(shipmentsToInsure).catch(() => {
                setError(true);
            });
        } else {
            onClose();
        }
    };

    return (
        <DialogContent>
            {!hasConfirm && (
                <section css={getInsuranceSelectorSectionStyles}>
                    <Typography variant="body1" bold>
                        {t('import-csv.insurance.coverage-title')}
                    </Typography>
                    <span css={getInsuranceInfoStyles}>
                        <Typography variant="body2">{t('import-csv.insurance.coverage-text')}</Typography>
                        <Link
                            variant={ButtonVariant.TEXT}
                            size={ButtonSize.SMALL}
                            href={getHelpCenterPage(HELP_CENTER_KEYS.insurance)}
                            target="_blank"
                        >
                            {t('insurance-selector.insurance-information.read-more')}
                        </Link>
                    </span>

                    <InsuranceSelector
                        currency="EUR"
                        name="insurance"
                        imageUrl=""
                        onChange={onChangeInsurance}
                        insurancesToDisplay={insurancesToDisplay}
                        numberShipmentsInBulk={numberOfShipments}
                        contentValue={0}
                        showInsuranceInformation={false}
                        insuranceTypeSelected={insuranceValue}
                    />
                </section>
            )}

            {hasConfirm && <InsuranceResponseCSVModal isError={error} />}

            <DialogActions css={getActionsStyles}>
                <Button onClick={hasConfirm ? onClose : onConfirm} disabled={!insuranceValue}>
                    {hasConfirm ? t('dialog.action.close') : t('import-csv.insurance.confirm')}
                </Button>
            </DialogActions>
        </DialogContent>
    );
};

export const InsuranceCSVModalContentMemoized = React.memo(InsuranceCSVModalContent);
