import { useEffectOnce } from '@packlink/utils';
import { useRefreshPrice } from './useRefreshPrice';

export function scrollToTop() {
    const mainWindow = document.querySelector('#main-scrollable-window');
    mainWindow?.scrollTo(0, 0);
}

export function useInitStep() {
    const { refreshPrice } = useRefreshPrice();

    useEffectOnce(() => {
        scrollToTop();
        refreshPrice();
    });
}
