import { UpsellInsurance, UpsellInsuranceType, UpsellService, UpsellServiceType } from '@types';
import {
    IPrice,
    IPriceInsurance,
    IPriceProducts,
    IShipment,
    ProShipment,
    Shipment,
    ShipmentInsurance,
} from '@packlink/packlink-sdk';

import { ServiceUtils } from './ServiceUtils';

export type ProductOptionsInsurances = { [K in UpsellInsuranceType]?: UpsellInsurance };
export type ProductOptionsServices = { [K in UpsellServiceType]?: UpsellService };

export interface IProductOptions {
    insurances: ProductOptionsInsurances;
    services: ProductOptionsServices;
}

export class UpsellUtils {
    static computeAvailableProducts(
        priceDetails: IPrice | undefined,
        printInStore: boolean,
        contentValue: number,
        shipment: Shipment,
        showAdditionalInsurance?: boolean,
    ): IProductOptions {
        const productOptions: IProductOptions = {
            insurances: {},
            services: {},
        };
        const { availableProducts, products: definedProducts } = priceDetails || {};
        const baseCoverage = availableProducts?.insurance?.baseCoverage ?? 0;
        const maxCoverage = availableProducts?.insurance?.maxCoverage;
        const insurance = availableProducts?.insurance;
        const proofOfDelivery = availableProducts?.proofOfDelivery;
        const adultSignature = availableProducts?.adultSignature;
        const additionalHandlingProduct = availableProducts?.additionalHandling;
        const cashOnDelivery = availableProducts?.cashOnDelivery;

        const isAdditionalHandlingSelectedFromCheckoutShipment = shipment.upsales?.additionalHandling?.available;
        const isAdditionalHandlingOptionalForSelectedShipment =
            isAdditionalHandlingSelectedFromCheckoutShipment || !definedProducts?.additionalHandling;

        if (cashOnDelivery) {
            productOptions.services[UpsellServiceType.CASH_ON_DELIVERY] = cashOnDelivery;
        }

        if (printInStore) {
            productOptions.services[UpsellServiceType.PRINT_IN_STORE] = {
                totalPrice: 0,
            };
        }

        if (proofOfDelivery) {
            productOptions.services[UpsellServiceType.PROOF_OF_DELIVERY] = proofOfDelivery;
        }

        if (adultSignature) {
            productOptions.services[UpsellServiceType.ADULT_SIGNATURE] = adultSignature;
        }

        if (additionalHandlingProduct && isAdditionalHandlingOptionalForSelectedShipment) {
            productOptions.services[UpsellServiceType.ADDITIONAL_HANDLING] = additionalHandlingProduct;
        }

        if (showAdditionalInsurance && baseCoverage === 0) {
            productOptions.insurances[UpsellInsuranceType.PACKLINK_INSURANCE] = {
                ...insurance,
            };
        }
        if (baseCoverage > 0) {
            const baseInsured = Math.min(baseCoverage, insurance?.insured ?? 0);

            const isValueAboveBaseCoverage = contentValue && contentValue > baseCoverage;
            const isBaseCoverageBelowMaxCoverage = contentValue && maxCoverage && baseCoverage < maxCoverage;

            if (showAdditionalInsurance && isValueAboveBaseCoverage && isBaseCoverageBelowMaxCoverage) {
                productOptions.insurances[UpsellInsuranceType.ENHANCED] = {
                    totalPrice: (insurance?.totalPrice ?? 0) + (proofOfDelivery?.totalPrice ?? 0),
                    basePrice: (insurance?.basePrice ?? 0) + (proofOfDelivery?.basePrice ?? 0),
                    insured: insurance?.insured,
                    baseCoverage,
                };
            } else {
                // TODO: This is a temp fix to avoid showing Standard when there's Enhanced
                // Should be reviewed when we have actual params from the backend
                // https://auctane.atlassian.net/browse/PCK-2694
                productOptions.insurances[UpsellInsuranceType.STANDARD] = {
                    totalPrice: 0,
                    insured: baseInsured,
                    baseCoverage,
                };
            }
        }
        const insurancesTypesKeys = Object.keys(productOptions.insurances);
        const insurancesTypesKeysLength = insurancesTypesKeys.length;
        if (
            insurancesTypesKeysLength &&
            !(insurancesTypesKeysLength == 1 && insurancesTypesKeys[0] === UpsellInsuranceType.STANDARD)
        ) {
            productOptions.insurances[UpsellInsuranceType.NO_INSURANCE] = {
                totalPrice: 0,
            };
        }
        return productOptions;
    }

    static computeAvailableInsurances(
        shipmentData: ProShipment['data'],
        insurance?: IPriceInsurance | ShipmentInsurance,
    ): UpsellInsuranceType[] {
        const availableInsurances: UpsellInsuranceType[] = [];
        const baseCoverage = insurance?.baseCoverage ?? 0;
        const maxCoverage = insurance?.maxCoverage;
        const contentValue = shipmentData.content?.value ?? 0;

        if (baseCoverage === 0) {
            return [...availableInsurances, UpsellInsuranceType.PACKLINK_INSURANCE, UpsellInsuranceType.NO_INSURANCE];
        } else {
            availableInsurances.push(UpsellInsuranceType.STANDARD);

            const isValueAboveBaseCoverage = contentValue && contentValue > baseCoverage;
            const isBaseCoverageBelowMaxCoverage = contentValue && maxCoverage && baseCoverage < maxCoverage;

            if ((isValueAboveBaseCoverage && isBaseCoverageBelowMaxCoverage) || !contentValue) {
                availableInsurances.push(UpsellInsuranceType.ENHANCED);
            }
        }
        return availableInsurances;
    }

    static getShipmentInsurance(
        shipmentData: ProShipment['data'],
        insurance?: IPriceInsurance | ShipmentInsurance,
    ): UpsellInsuranceType {
        const baseCoverage = insurance?.baseCoverage ?? 0;
        const hasBaseCoverage = baseCoverage > 0;
        const hasInsurance = shipmentData.upsales?.insurance?.available;
        const contentValue = shipmentData.content?.value ?? 0;

        if (hasInsurance && hasBaseCoverage && contentValue > baseCoverage) {
            return UpsellInsuranceType.ENHANCED;
        } else if (hasInsurance) {
            return UpsellInsuranceType.PACKLINK_INSURANCE;
        } else if (hasBaseCoverage) {
            return UpsellInsuranceType.STANDARD;
        } else {
            return UpsellInsuranceType.NO_INSURANCE;
        }
    }

    // Default Insurance type in SidePanel to avoid an extra click to insure the shipment
    static getDefaultInsuranceType(
        shipmentData: ProShipment['data'],
        insurance?: IPriceInsurance | ShipmentInsurance,
    ): UpsellInsuranceType {
        const baseCoverage = insurance?.baseCoverage ?? 0;
        const hasInsuredSelected = shipmentData?.upsales?.insurance?.available;
        const hasProofOfDeliverySelected = shipmentData.upsales?.proofOfDelivery?.available;

        if (baseCoverage) {
            if (hasInsuredSelected) {
                return UpsellInsuranceType.ENHANCED;
            } else {
                return hasProofOfDeliverySelected ? UpsellInsuranceType.ENHANCED : UpsellInsuranceType.STANDARD;
            }
        }
        return UpsellInsuranceType.PACKLINK_INSURANCE;
    }

    // Default manual checkout Insurance type where the user should click on the insurance selector
    static getDefaultCheckoutInsuranceType(
        baseCoverage: number,
        upsales?: ProShipment['data']['upsales'],
    ): UpsellInsuranceType | undefined {
        const hasInsuredSelected = upsales?.insurance?.available;

        if (baseCoverage) {
            if (hasInsuredSelected) {
                return UpsellInsuranceType.ENHANCED;
            }
        }
        if (hasInsuredSelected) {
            return UpsellInsuranceType.PACKLINK_INSURANCE;
        }
        return undefined;
    }

    static getShipmentServices(shipmentData: ProShipment['data']): UpsellServiceType[] {
        const services: UpsellServiceType[] = [];
        const adultSignature = shipmentData.upsales?.adultSignature?.available;
        const additionalHandling = shipmentData.upsales?.additionalHandling?.available;
        const printInStore = shipmentData.upsales?.printInStore?.available;
        const proofOfDelivery = shipmentData.upsales?.proofOfDelivery?.available;

        adultSignature && services.push(UpsellServiceType.ADULT_SIGNATURE);
        additionalHandling && services.push(UpsellServiceType.ADDITIONAL_HANDLING);
        printInStore && services.push(UpsellServiceType.PRINT_IN_STORE);
        proofOfDelivery && services.push(UpsellServiceType.PROOF_OF_DELIVERY);

        return services;
    }

    static setShipmentInsurance(
        shipmentData: ProShipment['data'],
        availableProducts: IPriceProducts | undefined,
        addInsurance: boolean,
    ): Shipment {
        if (!availableProducts) {
            return shipmentData;
        }

        const shipmentDataJSON = shipmentData.toJSON();

        const baseCoverage = availableProducts?.insurance?.baseCoverage ?? 0;
        const maxCoverage = availableProducts?.insurance?.maxCoverage ?? 0;
        const insured = availableProducts?.insurance?.insured;
        const proofOfDelivery = availableProducts?.proofOfDelivery;
        const contentValue = shipmentDataJSON.content.value;
        // Services that don't provide insurance (Colissimo) have baseCoverage 0 and maxCoverage 0
        const canHaveInsurance = addInsurance && contentValue > baseCoverage && baseCoverage < maxCoverage;

        const newShipmentData: IShipment = {
            ...shipmentDataJSON,
            upsales: {
                ...shipmentDataJSON.upsales,
                insurance: {
                    available: canHaveInsurance,
                    amount: canHaveInsurance ? insured : 0,
                },
                proofOfDelivery: {
                    available: canHaveInsurance
                        ? !!proofOfDelivery
                        : shipmentDataJSON.upsales.proofOfDelivery.available,
                },
            },
        };

        return Shipment.deserialize(newShipmentData);
    }

    static getShipmentStatusProtection(shipments: ProShipment[]) {
        let isProtectedShipment = false;
        let someShipmentPartiallyProtected = false;

        shipments?.forEach((shipment) => {
            const insuranceType = UpsellUtils.getShipmentInsurance(shipment.data, shipment.data.upsales?.insurance);
            const availableOptions = UpsellUtils.computeAvailableInsurances(
                shipment.data,
                shipment.data.upsales?.insurance,
            );
            const isEnhancedOptionAvailable = availableOptions?.includes(UpsellInsuranceType.ENHANCED);

            const hasNoInsuranceSelected = insuranceType === UpsellInsuranceType.NO_INSURANCE;

            const serviceException = ServiceUtils.getServiceTranslationKey(
                'insuranceException',
                shipment.data.service?.carrierName,
                shipment.data.service?.name,
            );

            if (
                serviceException ||
                (!isEnhancedOptionAvailable && !hasNoInsuranceSelected) ||
                insuranceType === UpsellInsuranceType.ENHANCED
            ) {
                isProtectedShipment = true;
            }
            if (
                insuranceType !== UpsellInsuranceType.ENHANCED &&
                isEnhancedOptionAvailable &&
                !hasNoInsuranceSelected
            ) {
                someShipmentPartiallyProtected = true;
            }
        });

        return { isProtectedShipment, someShipmentPartiallyProtected };
    }
}
