import { css, SerializedStyles } from '@emotion/react';
import { Theme } from '@shipengine/giger-theme';

export const getParcelsDeleteLinkStyles = (theme: Theme): SerializedStyles =>
    css({
        float: 'right',
        marginTop: theme.spacing(),
        padding: 0,
    });

export const getParcelFormStyles = (): SerializedStyles =>
    css({
        display: 'flex',
        alignItems: 'center',
    });
