export enum IntegrationRoute {
    ACTIVATE_START_FLOW = 'activate/start-flow',
    AMAZON_ACTIVATE_COMPLETE_FLOW = 'amazon/activate/complete-flow',
    AMAZON_ACTIVATE_START_FLOW = 'amazon/activate/start-flow',
    EBAY_ACTIVATE_COMPLETE_FLOW = 'ebay/activate/complete-flow',
    EBAY_ACTIVATE_START_FLOW = 'ebay/activate/start-flow',
    ETSY_ACTIVATE_COMPLETE_FLOW = 'etsy/activate/complete-flow',
    ETSY_ACTIVATE_START_FLOW = 'etsy/activate/start-flow',
    SHIPENGINE_OAUTH_DUMMY_ACTIVATE_COMPLETE_FLOW = 'shipengine_oauth_dummy/activate/complete-flow',
    SHIPENGINE_OAUTH_DUMMY_ACTIVATE_START_FLOW = 'shipengine_oauth_dummy/activate/start-flow',
}
