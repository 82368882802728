export enum Ecommerces {
    Aliexpress = 'Aliexpress',
    Amazon = 'Amazon',
    Ebay = 'eBay',
    Etsy = 'Etsy',
    Wizishop = 'WiziShop',
    DummyMarketplace = 'Dummy Marketplace',
    Discogs = 'Discogs',
    OAuthDummy = 'OAuthDummy',
}

export enum EcommerceAlias {
    Aliexpress = 'aliexpress',
    Amazon = 'amazon',
    Ebay = 'ebay',
    Etsy = 'etsy',
    Wizishop = 'wizishop_module',
    DummyMarketplace = 'shipengine_dummy-marketplace',
    Discogs = 'shipengine_discogs',
    OAuthDummy = 'shipengine_oauth_dummy',
}

// Properties are in lowercase because they are used to get the name showed in connection card
// for tour-onboarding-integration experience and the value is retrieve from the alias
export enum EcommerceName {
    aliexpress = 'Aliexpress',
    amazon = 'Amazon',
    ebay = 'Ebay',
    etsy = 'Etsy',
}
