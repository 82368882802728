import debounce from 'lodash.debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Form, getIn, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Address, CashOnDelivery as CashOnDeliveryModel, Content, IAddress, Currency } from '@packlink/packlink-sdk';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { AppDispatch } from '@store';
import { Button, Checkbox, Grid, GridChild, Icon, ISelectAutocompleteOption, Typography } from '@shipengine/giger';
import { ContentAutocomplete } from '@components/ContentAutocomplete/ContentAutocomplete';
import { ContentValue } from '@components/ContentValue/ContentValue';
import { DropoffInfo } from '@components/DropoffInfo/DropoffInfo';
import { FormField } from '@shipengine/formik-giger';
import { IconNames } from '@shipengine/giger-theme';
import { InsuranceSection } from '@components/Checkout/DetailsStep/InsuranceSection/InsuranceSection';
import { Origin, UpsellInsuranceType, UpsellServiceType } from '@types';
import { SaveAddress } from '@components/SaveAddress/SaveAddress';
import { SelectAddressBook } from '@components/SelectAddress/SelectAddressBook';
import { ServiceCollection } from '@components/Checkout/DetailsStep/ServiceCollection/ServiceCollection';
import { ServiceUtils } from '@utils/ServiceUtils';
import { TermsAgreementCheckbox } from '@components/TermsAgreement/TermsAgreementCheckbox';
import { TermsAndConditions } from '@components/TermsAndConditions/TermsAndConditions';
import { UPSTrademarkNoticeFooter } from '@components/UPSTrademarkNotice/UPSTrademarkNoticeFooter';
import { UpsellUtils } from '@utils/UpsellUtils';
import { UserAddress } from '@components/Checkout/DetailsStep/UserAddress/UserAddress';
import { getAddressStepContinueTranslationKey } from '@store/selectors/checkout-steps';
import {
    getCheckoutAdditionalData,
    getCheckoutContent,
    getCheckoutDropoffPointId,
    getCheckoutFrom,
    getCheckoutInsurancePrice,
    getCheckoutPriceDetails,
    getCheckoutService,
    getCheckoutShipment,
    getCheckoutThirdPartyPreferences,
    getCheckoutTo,
    getIsCarrierUPSFromService,
    getShowAdditionalInsurance,
} from '@store/selectors/checkout';
import { getIsPaymentStepSkippable } from '@store/selectors/payment';
import { hasAddressBookChanged, showSaveWarehouse } from '@components/Checkout/utils/address';
import {
    setCashOnDeliveryAction,
    setCheckoutAdditionalHandling,
    setCheckoutAdultSignature,
    setCheckoutContent,
    setCheckoutProofOfDelivery,
} from '@store/actions/checkout';
import { useAmplitude } from '@hooks/useAmplitude';
import { useRefreshPrice } from '@components/Checkout/hooks/useRefreshPrice';
import { useTranslation } from '@packlink/translation-provider';

import { IDetailsStepForm } from '../DetailsStep';
import { SwitchCard } from '../SwitchCard/SwitchCard';
import { CashOnDelivery, ICashOnDeliveryForm } from '../CashOnDelivery/CashOnDelivery';
import { getDetailsFormHeadingStyles, getSelectAddressToStyles, getSwitchCardPriceStyles } from './DetailsFormStyles';
import { useThirdPartyPreferencesBehavior } from '@common/hooks/useThirdPartyPreferencesBehavior';
import { useWarehouses } from '@common/hooks/useWarehouses';

export interface IDetailsForm extends IAddress {
    postalCode?: ISelectAutocompleteOption;
    country?: ISelectAutocompleteOption;
}

interface IDetailsFormProps {
    isDisabledSubmit: boolean;
}

export const DetailsForm = ({ isDisabledSubmit }: IDetailsFormProps): JSX.Element => {
    const { t } = useTranslation();
    const { values, setFieldValue, isSubmitting } = useFormikContext<IDetailsStepForm>();
    const dispatch = useDispatch<AppDispatch>();
    const fromValues: IDetailsForm = getIn(values, 'from');
    const toValues: IDetailsForm = getIn(values, 'to');

    const [isUpdatedAddress, setIsUpdatedAddress] = useState<boolean>(false);
    const [addressBookSaved, setAddressBookSaved] = useState<boolean>(false);
    const [latestAddress, setLatestAddress] = useState<IDetailsForm>(toValues);

    const shipment = useSelector(getCheckoutShipment);
    const checkoutTo = useSelector(getCheckoutTo);
    const checkoutFrom = useSelector(getCheckoutFrom);
    const buttonTranslation = useSelector(getAddressStepContinueTranslationKey);
    const insurancePrice = useSelector(getCheckoutInsurancePrice);
    const priceDetails = useSelector(getCheckoutPriceDetails);
    const selectedService = useSelector(getCheckoutService);
    const checkoutContent = useSelector(getCheckoutContent);
    const checkoutDropoffPointId = useSelector(getCheckoutDropoffPointId);
    const thirdPartyPreferredDropoffId = useSelector(getCheckoutThirdPartyPreferences)?.dropoffPointId;
    const additionalData = useSelector(getCheckoutAdditionalData);
    const { warehouses } = useWarehouses();
    const isPaymentStepSkippable = useSelector(getIsPaymentStepSkippable);
    const isUPSService = useSelector(getIsCarrierUPSFromService);
    const showAdditionalInsurance = useSelector(getShowAdditionalInsurance);

    const { thirdPartyPreferencesEnabled } = useThirdPartyPreferencesBehavior(shipment.source);

    const { sendAmplitudeClickEvent } = useAmplitude();
    const { refreshPrice } = useRefreshPrice();

    const contentValue = checkoutContent?.value ?? 0;
    const currency = selectedService?.currency ?? 'EUR';
    const { hasDropoffDestination, hasDropoffOrigin } = ServiceUtils.getServiceFlags(selectedService);
    const showServiceCollection = selectedService && !hasDropoffOrigin;

    const showSaveWarehouseFlag = showSaveWarehouse(
        additionalData.selectedWarehouseId as string,
        warehouses,
        checkoutFrom,
        fromValues,
    );

    const showSaveAddressBook = useMemo(() => {
        const hasChanged = hasAddressBookChanged(hasDropoffDestination, checkoutTo, latestAddress);
        if (!hasChanged && values.addressBookChecked) {
            setFieldValue('addressBookChecked', false);
        }

        if (addressBookSaved && hasChanged) {
            setAddressBookSaved(false);
            setIsUpdatedAddress(true);
        }
        return hasChanged;
    }, [hasDropoffDestination, checkoutTo, latestAddress, addressBookSaved, setFieldValue, values.addressBookChecked]);

    const someAvailableProducts = useMemo(() => {
        const availableProducts = UpsellUtils.computeAvailableProducts(
            priceDetails,
            false,
            contentValue,
            shipment,
            showAdditionalInsurance,
        );

        return {
            insurances: availableProducts.insurances,
            showAdultSignature: Object.keys(availableProducts.services)?.includes(UpsellServiceType.ADULT_SIGNATURE),
            showAdditionalHandling: Object.keys(availableProducts.services)?.includes(
                UpsellServiceType.ADDITIONAL_HANDLING,
            ),
            cashOnDelivery: availableProducts.services[UpsellServiceType.CASH_ON_DELIVERY],
            proofOfDelivery: availableProducts.services[UpsellServiceType.PROOF_OF_DELIVERY],
        };
        // In this case, we want to recalculate this only when the priceDetails or contentValue changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentValue, priceDetails]);

    const showAdditionalOptions =
        someAvailableProducts.showAdultSignature ||
        someAvailableProducts.showAdditionalHandling ||
        someAvailableProducts.cashOnDelivery?.basePrice ||
        someAvailableProducts.proofOfDelivery;

    const isProofOfDeliveryAvailable = !!someAvailableProducts.proofOfDelivery;
    const baseCoverage = priceDetails?.availableProducts?.insurance?.baseCoverage ?? 0;

    const updateContent = (newContentData: Partial<Content>): void => {
        dispatch(
            setCheckoutContent(
                Content.deserialize({
                    ...checkoutContent?.toJSON(),
                    ...newContentData,
                }),
            ),
        );
    };

    const contentDescriptionHandler = (description?: string) => {
        updateContent({ description });
        setFieldValue('description', description);
    };

    const debouncedRefreshPrice = useMemo(() => debounce(refreshPrice, 300), [refreshPrice]);

    const contentValueHandler = (value: string) => {
        updateContent({ value: parseFloat(value) });
        debouncedRefreshPrice();
        setFieldValue('value', value);
    };

    const adultSignatureHandler = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('adultSignature', checked);
        dispatch(setCheckoutAdultSignature(checked));
        refreshPrice();
    };

    const proofOfDeliveryHandler = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('proofOfDelivery', checked);
        dispatch(setCheckoutProofOfDelivery(checked));
        refreshPrice();
    };

    const additionalHandlingHandler = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('additionalHandling', checked);
        dispatch(setCheckoutAdditionalHandling(checked));
        refreshPrice();
    };

    const cashOnDeliveryHandler = (formValues: ICashOnDeliveryForm) => {
        setFieldValue('cashOnDelivery', { ...values.cashOnDelivery, ...formValues });
        dispatch(setCashOnDeliveryAction(CashOnDeliveryModel.deserialize({ ...values.cashOnDelivery, ...formValues })));
        refreshPrice();
    };

    const secondHandHandler = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
        updateContent({ secondHand: checked });
        refreshPrice();
    };

    const handleSelectAddressBook = (address: IAddress): void => {
        setAddressBookSaved(true);
        setIsUpdatedAddress(false);
        const latestAddress = {
            ...values.to,
            id: address.id,
            company: address.company,
            email: address.email,
            firstName: address.firstName,
            lastName: address.lastName,
            phone: address.phone,
            street1: address.street1,
        };
        setLatestAddress(latestAddress);
        setFieldValue('to', latestAddress);
    };

    const onClearAddressBook = (): void => {
        setAddressBookSaved(false);
        setIsUpdatedAddress(false);
        const emptyAddress = {
            ...values.to,
            id: '',
            company: '',
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            street1: '',
        };
        setLatestAddress(emptyAddress);
        setFieldValue('to', emptyAddress);
    };

    const handleWarehouseChange = (checked: boolean): void => {
        setFieldValue('warehouseChecked', checked);
    };

    const handleSavedAddressChange = (checked: boolean): void => {
        setFieldValue('addressBookChecked', checked);
    };

    const handleTermsAndConditions = ({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>): void => {
        setFieldValue('termsAndConditions', !values.termsAndConditions);
        const formOrigin = 'Address Details';

        const eventStatus = checked
            ? AmplitudeEvents.CHECK_CARRIERS_POLICIES
            : AmplitudeEvents.UNCHECK_CARRIERS_POLICIES;

        sendAmplitudeClickEvent(eventStatus, { [AmplitudeProperties.FROM_STEP]: formOrigin });
    };

    const [isProofOfDeliveryDisabled, setIsProofOfDeliveryDisabled] = useState<boolean>(false);

    useEffect(() => {
        const value = values.value ?? '';
        const isChecked =
            values.insuranceType === UpsellInsuranceType.ENHANCED &&
            isProofOfDeliveryAvailable &&
            parseFloat(value) > baseCoverage;

        setIsProofOfDeliveryDisabled(isChecked);
        setFieldValue('proofOfDelivery', isChecked);
        dispatch(setCheckoutProofOfDelivery(isChecked));
    }, [values.insuranceType, values.value, isProofOfDeliveryAvailable, setFieldValue, dispatch, baseCoverage]);

    return (
        <Form>
            <Grid noPadding>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <UserAddress origin={Origin.FROM} />
                    {showSaveWarehouseFlag && <SaveAddress defaultIsChecked onChange={handleWarehouseChange} />}
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <UserAddress origin={Origin.TO} />
                    <div css={getSelectAddressToStyles}>
                        {!hasDropoffDestination && (
                            <SelectAddressBook
                                address={checkoutTo}
                                onClear={onClearAddressBook}
                                onSelect={handleSelectAddressBook}
                            />
                        )}
                        {showSaveAddressBook && (
                            <SaveAddress
                                defaultIsChecked={false}
                                isAddressBook
                                isUpdatedAddress={isUpdatedAddress}
                                onChange={handleSavedAddressChange}
                            />
                        )}
                    </div>
                </GridChild>
                {showServiceCollection && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <ServiceCollection />
                    </GridChild>
                )}
                {hasDropoffDestination && selectedService?.id && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <DropoffInfo
                            origin={checkoutTo as Address<IAddress>}
                            serviceId={selectedService?.id}
                            dropoffPointId={
                                checkoutDropoffPointId
                                    ? checkoutDropoffPointId
                                    : thirdPartyPreferencesEnabled
                                      ? thirdPartyPreferredDropoffId
                                      : undefined
                            }
                            showDropoffRequiredError={
                                isSubmitting && !thirdPartyPreferredDropoffId && !checkoutDropoffPointId
                            }
                            showDropoffSelection
                        />
                    </GridChild>
                )}
                <GridChild colSpan={12} colSpanTablet={6} colStartTablet={1}>
                    <Typography css={getDetailsFormHeadingStyles} variant="heading5" component="h3" bold>
                        {t('details-form.content.title')}
                    </Typography>
                    <ContentAutocomplete onChange={contentDescriptionHandler} />
                    <Checkbox
                        value="second-hand"
                        name="is_second_hand"
                        label={t('content-panel.second-hand')}
                        onChange={secondHandHandler}
                        checked={!!checkoutContent?.secondHand}
                    />

                    <ContentValue value={contentValue.toString()} onChange={contentValueHandler} currency={currency} />
                </GridChild>
                {insurancePrice && Object.keys(someAvailableProducts.insurances).length > 0 && (
                    <GridChild colSpan={12}>
                        <InsuranceSection
                            baseCoverage={baseCoverage}
                            availableInsurances={someAvailableProducts.insurances}
                            currency={currency}
                            contentValue={contentValue}
                            isSecondHand={!!checkoutContent?.secondHand}
                            hasProofOfDelivery={isProofOfDeliveryAvailable}
                        />
                    </GridChild>
                )}
                {showAdditionalOptions && (
                    <GridChild colSpan={12}>
                        <Typography css={getDetailsFormHeadingStyles} variant="heading5" component="h3" bold>
                            {t('details-form.additional-options.title')}
                        </Typography>
                    </GridChild>
                )}
                {someAvailableProducts.proofOfDelivery && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <SwitchCard
                            name="proofOfDelivery"
                            checked={!!values?.proofOfDelivery}
                            handleChange={proofOfDeliveryHandler}
                            disabled={isProofOfDeliveryDisabled}
                        >
                            <div>
                                <Typography variant="small" css={getSwitchCardPriceStyles}>
                                    {Currency.format(someAvailableProducts.proofOfDelivery.basePrice ?? 0, currency)}
                                </Typography>
                            </div>
                        </SwitchCard>
                    </GridChild>
                )}
                {someAvailableProducts.showAdultSignature && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <SwitchCard
                            name="adultSignature"
                            checked={!!values?.adultSignature}
                            handleChange={adultSignatureHandler}
                        />
                    </GridChild>
                )}
                {someAvailableProducts.showAdditionalHandling && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <SwitchCard
                            name="additionalHandling"
                            checked={!!values?.additionalHandling}
                            handleChange={additionalHandlingHandler}
                        />
                    </GridChild>
                )}
                {someAvailableProducts.cashOnDelivery?.basePrice && (
                    <GridChild colSpan={12} colSpanTablet={6}>
                        <CashOnDelivery
                            handler={cashOnDeliveryHandler}
                            currency={currency}
                            price={someAvailableProducts.cashOnDelivery.basePrice}
                        />
                    </GridChild>
                )}
                <GridChild colSpan={12} colSpanTablet={6} colStart={1}>
                    {isPaymentStepSkippable && (
                        <FormField name="termsAndConditions">
                            <TermsAgreementCheckbox
                                handleChange={handleTermsAndConditions}
                                checked={getIn(values, 'termsAndConditions')}
                            />
                        </FormField>
                    )}
                </GridChild>
                <GridChild colSpan={12} colSpanTablet={6}>
                    <div>
                        <Button isFullWidth type="submit" disabled={isDisabledSubmit}>
                            <Typography variant="body1" bold>
                                {t(buttonTranslation)}
                            </Typography>
                            <Icon name={IconNames.ARROW_RIGHT} />
                        </Button>
                        <TermsAndConditions buttonText={t(buttonTranslation)} />
                        {isUPSService && <UPSTrademarkNoticeFooter />}
                    </div>
                </GridChild>
            </Grid>
        </Form>
    );
};
