import { useCallback, useState } from 'react';
import { IShippingRule } from '@packlink/packlink-sdk';
import { useEffectOnce } from '@packlink/utils';
import { ISelectAutocompleteOption } from '@shipengine/giger';
import { ActionOps, EMPTY_ACTION, EMPTY_CONDITION, EMPTY_ITEM } from '../constants';
import { IFormItem, IRuleForm } from '../types/formTypes';
import { useFormData } from './useFormData';
import { useFormFetch } from './useFormFetch';

export function useInitialValues(selectedRule?: IShippingRule): IRuleForm {
    const formData = useFormData();
    const fetchByIdentifier = useFormFetch();
    const [actions, setActions] = useState([EMPTY_ACTION]);
    const [conditions, setConditions] = useState([EMPTY_CONDITION]);
    const [overwrite, setOverwrite] = useState(true);

    // TODO: maybe refactor these 2 populate functions to use one common
    const populateActions = useCallback(() => {
        if (!selectedRule) return;

        Promise.all(
            selectedRule.actions.map(async (action): Promise<IFormItem> => {
                const valueOptions: ISelectAutocompleteOption[] | void = await fetchByIdentifier[action.identifier]?.();

                return {
                    identifier:
                        formData.actionOptions.find((actionOption) => actionOption.value === action.identifier) ||
                        EMPTY_ITEM,
                    operation:
                        formData.operatorsByActionIdentifier[action.identifier].find(
                            (operation) => operation.value === action.operation,
                        ) || EMPTY_ITEM,
                    value: valueOptions?.find((option) => option.value === action.value) || {
                        label: '',
                        value: action.value.toString(),
                    },
                };
            }),
        ).then(setActions);
    }, [fetchByIdentifier, formData.actionOptions, formData.operatorsByActionIdentifier, selectedRule]);

    const populateConditions = useCallback(() => {
        if (!selectedRule) return;

        Promise.all(
            selectedRule.conditions.map(async (condition): Promise<IFormItem> => {
                const valueOptions: ISelectAutocompleteOption[] | void =
                    await fetchByIdentifier[condition.identifier]?.();

                return {
                    identifier:
                        formData.conditionOptions.find((actionOption) => actionOption.value === condition.identifier) ||
                        EMPTY_ITEM,
                    operation:
                        formData.operatorsByConditionIdentifier[condition.identifier].find(
                            (operation) => operation.value === condition.operation,
                        ) || EMPTY_ITEM,
                    value: valueOptions?.find((option) => option.value === condition.value) || {
                        label: '',
                        value: condition.value.toString(),
                    },
                };
            }),
        ).then((data) => {
            setConditions(data);
        });
    }, [fetchByIdentifier, formData.conditionOptions, formData.operatorsByConditionIdentifier, selectedRule]);

    useEffectOnce(() => {
        if (!selectedRule) return;

        populateActions();
        populateConditions();

        // If any action is overwriting, all are, so we can check one directly.
        setOverwrite(selectedRule.actions[0].operation === ActionOps.OVERWRITE);
    });

    return {
        id: selectedRule?.id ?? '',
        enabled: selectedRule?.enabled ?? true,
        overwrite,
        alias: selectedRule?.alias ?? '',
        conditions,
        actions,
    };
}
