import React from 'react';

import { useDebounce, useMountedState } from '@packlink/utils';

export function useDebouncedValue<T>(value: T, ms: number) {
    const isMounted = useMountedState();
    const [debouncedValue, setDebouncedValue] = React.useState(value);
    useDebounce(() => isMounted() && setDebouncedValue(value), ms, [value]);

    return debouncedValue;
}
