import { Navigate, Route, Routes } from 'react-router';
import { APP_ROUTE } from '@router/routes';
import { OnboardingLayout } from './OnboardingLayout';
import { OnboardingSelectPlan } from './OnboardingSelectPlan';
// import { OnboardingWelcome } from './OnboardingWelcome';
import { OnboardingRoute } from '../routes';

export function OnboardingRouter(): JSX.Element {
    return (
        <Routes>
            <Route element={<OnboardingLayout />}>
                <Route path="*" element={<Navigate to={APP_ROUTE.ONBOARDING.SELECT_PLAN} />} />
                <Route index path={OnboardingRoute.SELECT_PLAN} element={<OnboardingSelectPlan />} />
                {/* TODO: PCK-7047: the page won't be available until conditional routing is done */}
                {/*<Route path={OnboardingRoute.WELCOME} element={<OnboardingWelcome />} />*/}
            </Route>
        </Routes>
    );
}
