import React, { useCallback, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useSelector } from 'react-redux';
import { useTranslation } from '@packlink/translation-provider';

import { apiClient } from '@sdk';
import {
    Button,
    ButtonVariant,
    DialogActions,
    DialogContent,
    Icon,
    IconSize,
    Link,
    Spinner,
    SpinnerSize,
    Typography,
} from '@shipengine/giger';

import { useTenantConfig } from '@packlink/tenant-config-provider';
import { getClientIdentifier } from '@store/selectors/client';
import { IGetUrlResponse, ImportRepository } from '@packlink/packlink-sdk';
import { IconNames } from '@shipengine/giger-theme';
import {
    getActionsStyles,
    getContentContainerStyles,
    getContentSectionStyles,
    getContentStyles,
    getErrorStyles,
    getHiddenStyles,
    getInfoPanelStyles,
    getStepStyles,
} from './ImportCSVModalContentStyles';

import { InfoPanel } from '@components/InfoPanel/InfoPanel';
import { InfoPanelStep } from '@components/InfoPanel/InfoPanelStep';
import { InfoPanelStepList } from '@components/InfoPanel/InfoPanelStepList';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { ITenantConfig } from '@types';
import { Status, useStatus } from '@hooks/useStatus';
import { useAmplitude } from '@hooks/useAmplitude';
import { useEvent } from '@packlink/event-handler';
import { CsvImportEvent, ICsvImportFinishedData, ICsvImportFinishedError } from '../../types';

interface IImportCSVModalContentProps {
    setShipmentReferences: (references: Array<string>) => void;
    setShowInsuranceContent: (showInsuranceContent: boolean) => void;
    cancelTokenSource: CancelTokenSource | undefined;
    setCancelTokenSource: (cts: CancelTokenSource | undefined) => void;
}

const importRepository = new ImportRepository(apiClient);

export const ImportCSVModalContent: React.FunctionComponent<IImportCSVModalContentProps> = ({
    setShipmentReferences,
    setShowInsuranceContent,
    cancelTokenSource,
    setCancelTokenSource,
}: IImportCSVModalContentProps): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const { sendAmplitudeHeaderClickEvent, sendAmplitudeTableViewEvent } = useAmplitude();
    const { isCSVTutorialAvailable } = useTenantConfig<ITenantConfig>();
    const {
        staticDocuments: { csv, csvTutorial },
        cdn: { url: cdnUrl },
    } = config;

    const csvExample = cdnUrl + csv[locale];
    const csvTutorialUrl = csvTutorial?.[locale] ? cdnUrl + csvTutorial[locale] : undefined;

    const { setStatus, status, isError, isLoading, isReady } = useStatus();
    const [message, setMessage] = useState('');

    const clientId = useSelector(getClientIdentifier);
    const { eventBind: importEndBind, eventUnbind: importEndUnbind } = useEvent<
        ICsvImportFinishedData | ICsvImportFinishedError
    >(clientId, CsvImportEvent.CSV_IMPORT_ENDED);

    const MAX_FILE_SIZE = 500000;
    let fileToUpload: Blob;

    const setModalStateTo = useCallback(
        (newStatus?: Status, notification?: string): void => {
            setStatus(newStatus);
            setMessage(notification ? t(notification) : '');
            if (newStatus === Status.ERROR) {
                sendAmplitudeTableViewEvent(AmplitudeEvents.CSV_IMPORT_KO);
            }
        },
        [sendAmplitudeTableViewEvent, setStatus, t],
    );

    const getRackspaceURLs = (): void => {
        setModalStateTo(Status.LOADING);
        importRepository
            .getUrl()
            .then(sendToRackspaceAndSaveDownloadUrl)
            .catch((): void => setModalStateTo(Status.ERROR, 'import-csv.errors.general'));
    };

    const saveDownloadUrl = (url: string): void => {
        importRepository
            .csv({ url, source: 'csv' })
            .then(() => {
                setModalStateTo(Status.LOADING);
            })
            .catch((): void => setModalStateTo(Status.ERROR, 'import-csv.errors.general'));
    };

    const sendToRackspaceAndSaveDownloadUrl = (urls: IGetUrlResponse): void => {
        const file = fileToUpload;
        const fileReader = new FileReader();
        fileReader.onloadend = (e: ProgressEvent<FileReader>): void => {
            if (e?.target?.result) {
                setCancelTokenSource(axios.CancelToken.source());
                const data = new window.Uint8Array(e.target.result as ArrayBuffer);

                axios({
                    url: urls.uploadUrl,
                    method: 'put',
                    data,
                    cancelToken: cancelTokenSource?.token,
                })
                    .then(() => {
                        saveDownloadUrl(urls.downloadUrl);
                    })
                    .catch(() => {
                        setModalStateTo(Status.ERROR, 'import-csv.errors.general');
                    });
            }
        };
        fileReader.readAsArrayBuffer(file);
    };

    const triggerFileChooser = (): void => {
        const fileSelectorInput = document.querySelector('#file') as HTMLInputElement;
        if (fileSelectorInput) {
            fileSelectorInput.click();
        }
        sendAmplitudeHeaderClickEvent(AmplitudeEvents.CSV_IMPORT_SELECTION);
    };

    const fileInputChangeListener = (e: React.ChangeEvent<HTMLInputElement>): void => {
        fileToUpload = e.target?.files?.[0] as Blob;
        if (!fileToUpload) {
            return;
        }
        if (fileToUpload.size > MAX_FILE_SIZE) {
            setModalStateTo(Status.ERROR, 'import-csv.errors.max-size');
            return;
        }

        getRackspaceURLs();
    };

    const onCsvImportFinished = useCallback(
        (data: ICsvImportFinishedData | ICsvImportFinishedError) => {
            if ('error' in data) {
                setModalStateTo(Status.ERROR, 'import-csv.errors.general');
            } else {
                const importedShipmentReferences = data.shipment_references;
                const numImports = importedShipmentReferences?.length ?? 0;
                setShipmentReferences(importedShipmentReferences);
                const successMessage = t('import-csv.success.header', {
                    numberOfShipments: numImports,
                });
                setModalStateTo(Status.READY, successMessage);
                setShowInsuranceContent(true);
                sendAmplitudeTableViewEvent(AmplitudeEvents.CSV_IMPORT_SUCCESS, {
                    [AmplitudeProperties.CSV_IMPORT_SHIPMENTS_COUNT]: numImports,
                });
            }
        },
        [sendAmplitudeTableViewEvent, setModalStateTo, setShipmentReferences, setShowInsuranceContent, t],
    );

    useEffect(() => {
        importEndBind(onCsvImportFinished);
        return () => {
            importEndUnbind(onCsvImportFinished);
        };
    }, [importEndBind, importEndUnbind, onCsvImportFinished]);

    useEffect(() => {
        return (): void => {
            setModalStateTo(undefined);
            setCancelTokenSource(undefined);
        };
    }, [setCancelTokenSource, setModalStateTo]);

    return (
        <DialogContent css={getContentStyles}>
            <section css={getContentContainerStyles}>
                {isLoading && (
                    <div css={getContentSectionStyles}>
                        <Spinner message={t('import-csv.uploading')} size={SpinnerSize.SIZE_LARGE} />
                    </div>
                )}
                {isError && (
                    <div css={getContentSectionStyles}>
                        <Icon name={IconNames.CANCEL_FILLED} size={IconSize.SIZE_LARGE} css={getErrorStyles} />
                        <Typography variant="body2">{message}</Typography>
                    </div>
                )}
                {(!status || isError) && (
                    <DialogActions css={getActionsStyles}>
                        <input
                            id="file"
                            type="file"
                            name="file"
                            css={getHiddenStyles}
                            onChange={fileInputChangeListener}
                            accept=".csv"
                            data-id="import-modal-content-input"
                        />
                        <Button onClick={triggerFileChooser}>{t('import-csv.select-csv-file')}</Button>
                        {!status && (
                            <Link variant={ButtonVariant.TEXT} target="self" href={csvExample} isButton>
                                {t('import-csv.sample')}
                            </Link>
                        )}
                    </DialogActions>
                )}
            </section>
            {!isReady && (
                <InfoPanel title={t('import-csv.steps-title')} collapsible={false} css={getInfoPanelStyles}>
                    <InfoPanelStepList>
                        <InfoPanelStep>
                            <p css={getStepStyles}>
                                {t('import-csv.before-uploading')} {t('import-csv.check-format')}
                            </p>
                            {csvTutorialUrl && isCSVTutorialAvailable && (
                                <p css={getStepStyles}>
                                    <a href={csvTutorialUrl} target="_blank" rel="noreferrer" download={csvTutorialUrl}>
                                        {t('import-csv.tutorial') + ' '}
                                    </a>
                                    {t('import-csv.more-info')}
                                </p>
                            )}
                        </InfoPanelStep>
                        <InfoPanelStep>
                            <p css={getStepStyles}>{t('import-csv.upload-csv')}</p>
                        </InfoPanelStep>
                        <InfoPanelStep>
                            <p css={getStepStyles}>{t('import-csv.select-carrier')}</p>
                        </InfoPanelStep>
                    </InfoPanelStepList>
                </InfoPanel>
            )}
        </DialogContent>
    );
};
