import { Navigate, useLocation, useNavigate } from 'react-router';
import { partition } from 'lodash';
import { useQuery } from 'react-query';

import { IconNames } from '@shipengine/giger-theme';
import { Trans, useTranslation } from '@packlink/translation-provider';
import { Inbox, IOrder, IShipment, Shipment } from '@packlink/packlink-sdk';

import { useToggle } from '@packlink/utils';

import {
    Button,
    ButtonColor,
    ButtonSize,
    ButtonVariant,
    Icon,
    IconSize,
    Link,
    Spinner,
    Typography,
} from '@shipengine/giger';

import Packlink from '@sdk';
import { inboxToRoute } from '@pages/router/utils/paths';
import { APP_ROUTE } from '@pages/router/routes';

import { PaymentSummaryCarriersList } from './components/PaymentSummaryCarriersList';
import { PaymentSummaryLabels } from './components/PaymentSummaryLabels';
import { PaymentSummaryErrorsModal } from './components/PaymentSummaryErrorsModal';

import {
    getBackButtonStyles,
    getBackButtonIconStyles,
    getBodyStyles,
    getContainerStyles,
    getHeaderIconStyles,
    getHeaderStyles,
    getSuccessMsgStyles,
    getSummaryStyles,
    getLoadingStyles,
} from './PaymentSummaryStyles';

interface IPaymentSummaryProps {
    order: IOrder;
}

export const PaymentSummary = ({ order }: IPaymentSummaryProps): JSX.Element => {
    const { t } = useTranslation();
    const { state: dialogOpened, toggle: toggleDialog } = useToggle(false);
    const navigate = useNavigate();

    const onGoToReadyToShip = () => navigate(inboxToRoute(Inbox.READY_FOR_SHIPPING));
    const onGoToDrafts = () => navigate(inboxToRoute(Inbox.DRAFT));

    const [orderShipmentsWithErrors, orderShipmentsPaid] = partition(order.shipments, (s) =>
        typeof s.status === 'boolean' ? !s.status : s.status >= 400,
    );

    const hasErrors = !!orderShipmentsWithErrors.length;

    const shipmentReferences = orderShipmentsPaid.map((s) => s.shipmentReference);

    const { data: paidShipments, isLoading } = useQuery<Shipment<IShipment>[]>(
        ['getShipmentsByReference', shipmentReferences],
        () => Packlink.v1.shipments.getShipmentsByReference(shipmentReferences),
    );

    return (
        <>
            <div css={getSummaryStyles}>
                <div css={getBackButtonStyles}>
                    <Button
                        color={ButtonColor.PRIMARY}
                        size={ButtonSize.SMALL}
                        variant={ButtonVariant.TEXT}
                        onClick={onGoToReadyToShip}
                    >
                        <Icon css={getBackButtonIconStyles} name={IconNames.CARDBOARD_BOX} size={IconSize.SIZE_SMALL} />
                        {t('payment-summary.button-ready-ship')}
                    </Button>
                </div>
                <div css={getHeaderStyles}>
                    <div css={getHeaderIconStyles(hasErrors)}>
                        <Icon name={hasErrors ? IconNames.ALERT : IconNames.CHECK_FILLED} size={IconSize.SIZE_LARGE} />
                    </div>
                    <Typography variant="heading3" component="h3" css={getSuccessMsgStyles}>
                        {t(hasErrors ? 'payment-summary.partial' : 'payment-summary.success')}
                    </Typography>
                    {hasErrors && (
                        <Trans
                            i18nKey={t('payment-summary.partial.subtitle', {
                                failed: orderShipmentsWithErrors.length,
                                total: order.shipments.length,
                            })}
                            components={[<Link key="link" onClick={toggleDialog} />]}
                            css={getSuccessMsgStyles}
                        />
                    )}
                </div>

                {isLoading ? (
                    <div css={getLoadingStyles}>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        <PaymentSummaryLabels shipments={paidShipments} />

                        <div css={getBodyStyles}>
                            <div css={getContainerStyles}>
                                <PaymentSummaryCarriersList shipmentsList={paidShipments} order={order} />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <PaymentSummaryErrorsModal
                isOpened={dialogOpened}
                shipments={orderShipmentsWithErrors}
                onClose={toggleDialog}
                onGoToDrafts={onGoToDrafts}
            />
        </>
    );
};

export function BulkPaymentSummary() {
    const location = useLocation();
    const state = location.state as { order: IOrder } | null;

    if (!state?.order) {
        return <Navigate to={APP_ROUTE.SHIPMENTS.INBOX_ALL} />;
    } else {
        return <PaymentSummary order={state.order} />;
    }
}
