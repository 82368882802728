import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';

import Packlink from '@sdk';
import { Origin, UpsellInsuranceType } from '@types';
import { TFunction, useTranslation } from '@packlink/translation-provider';
import {
    getCashOnDelivery,
    getCheckoutAdditionalData,
    getCheckoutContent,
    getCheckoutDropoffPointId,
    getCheckoutFrom,
    getCheckoutInsurancePrice,
    getCheckoutInsuranceType,
    getCheckoutParcels,
    getCheckoutPriceDetails,
    getCheckoutService,
    getCheckoutShipment,
    getCheckoutSource,
    getCheckoutTo,
    getIsCheckoutBusy,
    getIsCustomsRequiredInCheckout,
    getSelectedShipmentAdditionalHandling,
    getSelectedShipmentAdultSignature,
    getSelectedShipmentProofOfDelivery,
    getShowAdditionalInsurance,
} from '@store/selectors/checkout';
import { getIsPaymentStepSkippable } from '@store/selectors/payment';
import { getLocationValidation } from '@components/LocationForm/locationFormValidation';

import { StepContent } from '../StepContent';
import { DetailsForm, IDetailsForm } from './DetailsForm/DetailsForm';
import { getUserAddressInitialValues } from './UserAddress/UserAddress';
import { getContentValueValidation } from '@components/ShipmentList/ShipmentPanel/ShipmentPanelContent/ShipmentPanelContentInfo';
import { getContentValidation } from '@components/ContentAutocomplete/ContentAutocomplete';
import { logSdkError } from '@utils/logger';
import { ServiceUtils } from '@utils/ServiceUtils';
import {
    resetCheckout,
    setAddressBookCheckedAction,
    setCheckoutAddressDetails,
    setCheckoutSelectedWarehouse,
} from '@store/actions/checkout';
import { AmplitudeEvents, AmplitudeProperties } from '@constants/amplitude';
import { showSaveWarehouse } from '../utils/address';
import { useToast } from '@shipengine/giger';
import { Address, CashOnDelivery, IWarehouseAddress } from '@packlink/packlink-sdk';
import { useCheckoutPath } from '@pages/checkout/hooks/useCheckoutPath';
import { CheckoutRoute } from '@pages/checkout/routes';
import { AppDispatch } from '@store';
import { useGoToPaymentStep } from '../hooks/useGoToPaymentStep';
import { useInitStep } from '../hooks/useInitStep';
import { useGoogleTagManager } from '@hooks/useGoogleTagManager';
import { useAmplitude } from '@hooks/useAmplitude';
import {
    getCashOnDeliveryInitialValues,
    getCashOnDeliveryValidation,
    ICashOnDeliveryForm,
} from './CashOnDelivery/CashOnDelivery';
import { useFormatCurrency } from '@hooks';
import { useWarehouses } from '@common/hooks/useWarehouses';

export interface IDetailsStepForm {
    addressBookChecked: boolean;
    from: IDetailsForm;
    termsAndConditions: boolean;
    to: IDetailsForm;
    warehouseChecked: boolean;
    adultSignature?: boolean;
    additionalHandling?: boolean;
    description?: string;
    insuranceType?: UpsellInsuranceType;
    value?: string;
    cashOnDelivery: ICashOnDeliveryForm;
    proofOfDelivery?: boolean;
}

export const DetailsStep = (): JSX.Element => {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { goToPaymentStep } = useGoToPaymentStep();
    const navigate = useNavigate();
    const getCheckoutPath = useCheckoutPath();
    const { sendAddToCartGtmEvent } = useGoogleTagManager();
    const { sendAmplitudeEvent, sendAmplitudeShipmentEvent } = useAmplitude();

    const to = useSelector(getCheckoutTo);
    const from = useSelector(getCheckoutFrom);
    const source = useSelector(getCheckoutSource);
    const checkoutContent = useSelector(getCheckoutContent);
    const selectedService = useSelector(getCheckoutService);
    const dropoffPointId = useSelector(getCheckoutDropoffPointId);
    const parcels = useSelector(getCheckoutParcels);
    const insuranceType = useSelector(getCheckoutInsuranceType);
    const adultSignature = useSelector(getSelectedShipmentAdultSignature);
    const proofOfDelivery = useSelector(getSelectedShipmentProofOfDelivery);
    const additionalHandling = useSelector(getSelectedShipmentAdditionalHandling);
    const priceDetails = useSelector(getCheckoutPriceDetails);
    const isCheckoutBusy = useSelector(getIsCheckoutBusy);
    const { warehouses, canAddWarehouse, handleRequestSaveWarehouse } = useWarehouses();
    const additionalData = useSelector(getCheckoutAdditionalData);
    const shipment = useSelector(getCheckoutShipment);
    const insurancePrice = useSelector(getCheckoutInsurancePrice);
    const isCustomsRequired = useSelector(getIsCustomsRequiredInCheckout);
    const isPaymentStepSkippable = useSelector(getIsPaymentStepSkippable);
    const showAdditionalInsurance = useSelector(getShowAdditionalInsurance);
    const cashOnDelivery = useSelector(getCashOnDelivery);

    useInitStep();

    const contentValue = checkoutContent?.value;
    const baseCoverageInsurance =
        (priceDetails?.availableProducts?.insurance?.baseCoverage || insurancePrice?.baseCoverage) ?? 0;

    const isInsuranceCoveredByService = contentValue
        ? baseCoverageInsurance >= contentValue
        : baseCoverageInsurance > 0;
    const shouldValidateInsurance = showAdditionalInsurance && !isInsuranceCoveredByService;
    const isDisabledSubmit = isCheckoutBusy || !priceDetails;

    useEffect(() => {
        const isShipmentMissingInfo = !from?.alpha2Code || !to?.alpha2Code;
        if (isShipmentMissingInfo) {
            dispatch(resetCheckout);
            navigate(getCheckoutPath(CheckoutRoute.INFO));
        } else if (!selectedService) {
            navigate(getCheckoutPath(CheckoutRoute.SERVICE));
        }
    }, [from?.alpha2Code, selectedService, to?.alpha2Code, dispatch, navigate, getCheckoutPath]);

    const initialPostalInfo = useMemo(
        () => ({
            from: {
                postalZoneId: additionalData.postal_zone_id_from as string,
                postalCodeId: additionalData.zip_code_id_from as string,
            },
            to: {
                postalZoneId: additionalData.postal_zone_id_to as string,
                postalCodeId: additionalData.zip_code_id_to as string,
            },
        }),
        [
            additionalData.postal_zone_id_from,
            additionalData.postal_zone_id_to,
            additionalData.zip_code_id_from,
            additionalData.zip_code_id_to,
        ],
    );

    const { hasDropoffDestination } = ServiceUtils.getServiceFlags(selectedService);

    const codPriceDetails = priceDetails?.availableProducts?.cashOnDelivery;
    const codMaxAmount = codPriceDetails?.maxAmount;
    const formattedMaxAmount = useFormatCurrency(codPriceDetails?.currency ?? 'EUR', codMaxAmount);

    const hideEmail = source?.includes('mirakl_');
    const toast = useToast(t);

    const getTermsValidation = useCallback(
        (t: TFunction): yup.BooleanSchema<boolean> =>
            isPaymentStepSkippable
                ? yup.bool().oneOf([true], t('form.error.carriers-terms')).required(t('form.error.carriers-terms'))
                : yup.bool(),
        [isPaymentStepSkippable],
    );

    const validationSchema = useMemo(() => {
        return yup.object().shape({
            from: getLocationValidation(Origin.FROM, false, t, false, false),
            to: getLocationValidation(Origin.TO, hasDropoffDestination, t, false, hideEmail),
            warehouseChecked: yup.bool(),
            addressBookChecked: yup.bool(),
            description: getContentValidation(t),
            value: getContentValueValidation(t),
            termsAndConditions: getTermsValidation(t),
            ...(shouldValidateInsurance && {
                insuranceType: yup.mixed<UpsellInsuranceType>().required(t('form.error.insurance-selection')),
            }),
            adultSignature: yup.bool(),
            additionalHandling: yup.bool(),
            cashOnDelivery: getCashOnDeliveryValidation(t, {
                max: codMaxAmount || 0,
                formatted: formattedMaxAmount || '',
            }),
            proofOfDelivery: yup.bool(),
        });
    }, [
        t,
        hasDropoffDestination,
        hideEmail,
        getTermsValidation,
        shouldValidateInsurance,
        codMaxAmount,
        formattedMaxAmount,
    ]);

    const initialValues = useMemo(
        (): IDetailsStepForm => ({
            from: getUserAddressInitialValues(from, initialPostalInfo.from),
            to: getUserAddressInitialValues(to, initialPostalInfo.to),
            warehouseChecked: true,
            addressBookChecked: false,
            description: checkoutContent?.description || '',
            value: checkoutContent?.value?.toString(),
            insuranceType,
            termsAndConditions: false,
            adultSignature,
            additionalHandling,
            cashOnDelivery: getCashOnDeliveryInitialValues(
                !!cashOnDelivery,
                CashOnDelivery.deserialize(cashOnDelivery),
            ),
            proofOfDelivery,
        }),
        [
            from,
            to,
            checkoutContent,
            initialPostalInfo,
            insuranceType,
            adultSignature,
            additionalHandling,
            cashOnDelivery,
            proofOfDelivery,
        ],
    );

    const createWarehouse = useCallback(
        ({ from }: IDetailsStepForm) => {
            function formValuesToWarehouse(values: IDetailsForm): IWarehouseAddress {
                return {
                    ...values,
                    id: values.id || '',
                    email: values.email || '',
                    address: values.street1 || '',
                    postalCodeId: values?.postalCode?.value || '',
                    postalZone: {
                        id: values?.country?.value || '',
                        translations: { [locale]: values.state || '' },
                    },
                    isDefault: false,
                    alias: `${from.firstName} ${from.lastName}`,
                };
            }
            handleRequestSaveWarehouse(formValuesToWarehouse(from), {
                onSuccess: (warehouse: IWarehouseAddress) => {
                    dispatch(setCheckoutSelectedWarehouse(warehouse.id));
                },
                onError: () => {
                    toast.error({ message: t('create-details.warehouse.error') });
                },
            });
        },
        [handleRequestSaveWarehouse, locale, dispatch, toast, t],
    );

    const submitForm = useCallback(
        (values: IDetailsStepForm): void => {
            if (
                (selectedService?.dropOff?.destination && !dropoffPointId) ||
                (isPaymentStepSkippable && !values.termsAndConditions)
            ) {
                return;
            }

            sendAddToCartGtmEvent([
                {
                    service: selectedService,
                    packages: parcels,
                    from,
                    to,
                    insuranceType,
                    source,
                },
            ]);

            sendAmplitudeEvent(AmplitudeEvents.SELECT_SHIPMENT_CONTENT, {
                [AmplitudeProperties.CATEGORY]: checkoutContent?.description,
            });

            const showSaveWarehouseFlag = showSaveWarehouse(
                additionalData.selectedWarehouseId as string,
                warehouses,
                from,
                values.from,
            );

            if (additionalData.selectedWarehouseId && showSaveWarehouseFlag) {
                dispatch(setCheckoutSelectedWarehouse(undefined));
            }

            if (canAddWarehouse && values.warehouseChecked && showSaveWarehouseFlag) {
                createWarehouse(values);
            }

            dispatch(setAddressBookCheckedAction(values.addressBookChecked));

            if (values.addressBookChecked) {
                to.id
                    ? Packlink.pro.addresses.put(to).catch(logSdkError)
                    : Packlink.pro.addresses
                          .post(to)
                          .then((address: Address) => {
                              dispatch(setCheckoutAddressDetails(address, Origin.TO));
                          })
                          .catch(logSdkError);
            }

            const amplitudeData = {
                [AmplitudeProperties.PRICE]: priceDetails?.price.basePrice,
                [AmplitudeProperties.ADDRESS_BOOK_CHECKED]: values.addressBookChecked,
                [AmplitudeProperties.CUSTOMS_REQUIRED]: isCustomsRequired,
                [AmplitudeProperties.INSURANCE]: insuranceType,
                [AmplitudeProperties.SERVICE_ID]: selectedService?.id,
            };

            if (isCustomsRequired) {
                sendAmplitudeShipmentEvent(AmplitudeEvents.CHECKOUT_CUSTOMS, shipment, amplitudeData);
                navigate(getCheckoutPath(CheckoutRoute.CUSTOMS), {
                    state: { carriersTermsAndConditions: values.termsAndConditions },
                });
            } else {
                goToPaymentStep(values.termsAndConditions);
            }
        },
        [
            selectedService,
            dropoffPointId,
            isPaymentStepSkippable,
            sendAddToCartGtmEvent,
            parcels,
            from,
            to,
            insuranceType,
            source,
            sendAmplitudeEvent,
            checkoutContent?.description,
            additionalData.selectedWarehouseId,
            warehouses,
            canAddWarehouse,
            dispatch,
            priceDetails?.price.basePrice,
            isCustomsRequired,
            createWarehouse,
            sendAmplitudeShipmentEvent,
            shipment,
            navigate,
            getCheckoutPath,
            goToPaymentStep,
        ],
    );

    return (
        <StepContent>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnBlur={true}
                onSubmit={submitForm}
            >
                <DetailsForm isDisabledSubmit={isDisabledSubmit} />
            </Formik>
        </StepContent>
    );
};
