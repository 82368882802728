import { useFormatCurrency } from '@hooks/useFormatCurrency';
import { IconNames } from '@shipengine/giger-theme';
import { IconPopover, IconSize, Typography, TypographyVariant, WithChildrenCommonProps } from '@shipengine/giger';
import { Currency, IPrice } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';
import { getCheckoutVoucher } from '@store/selectors/checkout';
import { useSelector } from 'react-redux';

import {
    getPriceSummaryIconStyles,
    getPriceSummaryItemsStyles,
    getPriceSummaryItemStyles,
    getPriceSummarySectionStyles,
} from './PriceSummaryStyles';

interface PriceSummaryProps {
    priceDetails: IPrice;
}

export const PriceSummary = ({ priceDetails }: PriceSummaryProps): JSX.Element => {
    const { products, price } = priceDetails;
    const { t } = useTranslation();
    const appliedVoucher = useSelector(getCheckoutVoucher);

    const insuranceLabel = products.insurance?.insured
        ? t('checkout.price-summary.insurance', {
              insured: Currency.format(products.insurance.insured || 0, price.currency),
          })
        : 'checkout.price-summary.insurance-extra';

    const taxPercentage = price.taxes && price.taxes[0].percentage;
    const taxesLabel = t('checkout.price-summary.taxes', { percentage: taxPercentage });
    const showInsurance = !!products.insurance?.basePrice && products.insurance.basePrice > 0;

    return (
        <section css={getPriceSummarySectionStyles}>
            <PriceSummaryItems>
                {/* ServicePrice */}
                <PriceSummaryItem
                    label={t('checkout.price-summary.service-price')}
                    price={products.porterage.basePrice}
                    currency={price.currency}
                />

                {/* Management fee */}
                {products.managementFee && (
                    <PriceSummaryItem
                        label={t('checkout.price-summary.management-fee')}
                        tooltip={t('checkout.price-summary.management-fee-info')}
                        price={products.managementFee?.basePrice}
                        currency={price.currency}
                    />
                )}

                {/* Insurance */}
                {showInsurance && (
                    <PriceSummaryItem
                        label={insuranceLabel}
                        price={products.insurance?.basePrice}
                        currency={price.currency}
                    />
                )}

                {/* Proof of delivery */}
                {products.proofOfDelivery && (
                    <PriceSummaryItem
                        label={t('checkout.price-summary.proof-of-delivery')}
                        price={products.proofOfDelivery?.basePrice}
                        currency={price.currency}
                    />
                )}

                {/* Adult signature */}
                {products.adultSignature && (
                    <PriceSummaryItem
                        label={t('checkout.price-summary.adult-signature')}
                        price={products.adultSignature?.basePrice}
                        currency={price.currency}
                    />
                )}

                {/* Additional handling */}
                {products.additionalHandling && (
                    <PriceSummaryItem
                        label={t('checkout.price-summary.additional-handling')}
                        price={products.additionalHandling?.basePrice}
                        currency={price.currency}
                    />
                )}

                {/* Cash on delivery */}
                {products.cashOnDelivery && (
                    <PriceSummaryItem
                        label={t('checkout.price-summary.cash-on-delivery')}
                        price={products.cashOnDelivery?.basePrice}
                        currency={price.currency}
                    />
                )}

                {/* Voucher */}
                {products.voucher && (
                    <PriceSummaryItem
                        label={appliedVoucher?.name || ''}
                        price={products.voucher?.basePrice}
                        currency={price.currency}
                    />
                )}
            </PriceSummaryItems>

            <PriceSummaryItems>
                <PriceSummaryItem
                    label={t('checkout.price-summary.price-before-taxes')}
                    price={price.basePrice}
                    currency={price.currency}
                    bold
                />
                <PriceSummaryItem
                    label={taxesLabel}
                    price={price.taxPrice}
                    currency={price.currency}
                    showAmount={!!(price.taxPrice && price.taxPrice > 0)}
                />
            </PriceSummaryItems>

            <PriceSummaryItems total>
                <PriceSummaryItem
                    label={t('checkout.price-summary.total-price')}
                    price={price.totalPrice}
                    currency={price.currency}
                    variant={'heading5'}
                    bold
                />
            </PriceSummaryItems>
        </section>
    );
};

interface PriceSummaryItemProps {
    label: string;
    tooltip?: number;
    price?: number;
    currency?: string;
    bold?: boolean;
    variant?: TypographyVariant;
    showAmount?: boolean;
}

const PriceSummaryItem = ({
    label,
    tooltip,
    price,
    currency,
    bold = false,
    variant = 'body2',
    showAmount = true,
}: PriceSummaryItemProps): JSX.Element => {
    const formattedAmount = useFormatCurrency(currency || '', price || 0);

    return (
        <div css={getPriceSummaryItemStyles}>
            <Typography component="dt" variant={variant} bold={bold}>
                {label}

                {tooltip && (
                    <IconPopover
                        css={getPriceSummaryIconStyles}
                        icon={IconNames.INFO}
                        size={IconSize.SIZE_SMALL}
                        placement="top"
                    >
                        <Typography variant="body2">{tooltip}</Typography>
                    </IconPopover>
                )}
            </Typography>
            <Typography component="dd" variant={variant} bold={bold}>
                {showAmount ? formattedAmount : '-'}
            </Typography>
        </div>
    );
};

interface PriceSummaryItemsProps {
    total?: boolean;
}

const PriceSummaryItems = ({
    total = false,
    children,
}: WithChildrenCommonProps<PriceSummaryItemsProps>): JSX.Element => {
    return <dl css={getPriceSummaryItemsStyles(total)}>{children}</dl>;
};
