import { SidePanel, SlideInPosition, useToast } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { PhoneNumberValue } from '@packlink/invoice-form';

import { getSidePanelClipPathStyles } from '@components/SidePanel/SidePanelStyles';
import { SidePanelHeader } from '@components/SidePanel/SidePanelHeader';
import { useState } from 'react';
import { PhoneNumberConfirmation } from './PhoneNumberConfirmation';
import { OTPVerification } from './OTPVerification';
import { OTPVerificationSuccess } from './OTPVerificationSuccess';
import { OTPVerificationError } from './OTPVerificationError';
import { API_ERRORS_OTP_MAPPING, API_ERRORS_OTP_MAPPING_KEYS } from '@constants/apiErrors';
import { useAmplitude } from '@hooks';
import { AmplitudeEvents } from '@constants/amplitude';
import { mapOtpErroKey } from '@utils/otpVerificationUtils';

export type PhoneNumberVerificationPanelProps = {
    isOpen: boolean;
    continueTranslationKey?: string;
    initialPhoneNumberValue?: PhoneNumberValue | string;
    alpha2Code?: string;
    onClose: VoidFunction;
    onVerified?: VoidFunction;
    onComplete?: VoidFunction;
};

export type PhoneNumberVerificationErrorData = {
    key: string;
    remainingTimeInMs?: number;
};

export function PhoneNumberVerificationPanel({
    alpha2Code,
    isOpen,
    continueTranslationKey = 'phone-verification.success.continue',
    initialPhoneNumberValue,
    onClose,
    onVerified,
    onComplete,
}: PhoneNumberVerificationPanelProps): JSX.Element {
    const { t } = useTranslation();
    const toast = useToast(t);
    const [step, setStep] = useState<'confirmation' | 'verification' | 'success' | 'error'>('confirmation');
    const [errorData, setErrorData] = useState<PhoneNumberVerificationErrorData>({
        key: API_ERRORS_OTP_MAPPING_KEYS.DEFAULT,
    });
    const [confirmedPhoneNumber, setConfirmedPhoneNumber] = useState<PhoneNumberValue>();
    const { sendAmplitudeEvent } = useAmplitude();

    const resetFlowAndClose = () => {
        onClose();
        setConfirmedPhoneNumber(undefined);
        setStep('confirmation');
    };

    const setSuccess = () => {
        setStep('success');
        onVerified?.();
    };

    const completeFlow = () => {
        onComplete?.();
        resetFlowAndClose();
    };

    const handleSuccessConfirmation = (phone: PhoneNumberValue) => {
        sendAmplitudeEvent(AmplitudeEvents.TWILIO_CODE_SENT);
        setConfirmedPhoneNumber(phone);
        setStep('verification');
    };

    const setError = async (errorCode?: string, remainingTimeInMs?: number) => {
        const newErrorKey = errorCode && mapOtpErroKey(errorCode);
        if (
            errorCode !== API_ERRORS_OTP_MAPPING.INVALID_OTP &&
            errorCode !== API_ERRORS_OTP_MAPPING.INVALID_PHONE_NUMBER
        ) {
            newErrorKey && setErrorData({ ...errorData, key: newErrorKey, remainingTimeInMs });
            setStep('error');
        } else {
            toast.error({
                message: t(newErrorKey),
            });
        }
    };

    return (
        <SidePanel
            css={getSidePanelClipPathStyles(SlideInPosition.RIGHT)}
            isOpen={isOpen}
            onBackdropClick={resetFlowAndClose}
            header={<SidePanelHeader onAction={resetFlowAndClose} title={t('phone-verification.panel.title')} />}
        >
            {step === 'confirmation' && (
                <PhoneNumberConfirmation
                    onBack={resetFlowAndClose}
                    onSuccess={handleSuccessConfirmation}
                    initialValue={confirmedPhoneNumber || initialPhoneNumberValue}
                    alpha2Code={alpha2Code}
                    onError={setError}
                />
            )}

            {step === 'verification' && !!confirmedPhoneNumber && (
                <OTPVerification
                    onBack={() => setStep('confirmation')}
                    onVerified={setSuccess}
                    onError={setError}
                    confirmedPhoneNumber={confirmedPhoneNumber.prefix.value + confirmedPhoneNumber.number}
                />
            )}

            {step === 'success' && (
                <OTPVerificationSuccess continueTranslationKey={continueTranslationKey} onComplete={completeFlow} />
            )}

            {step === 'error' && <OTPVerificationError errorData={errorData} />}
        </SidePanel>
    );
}
