import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';

import { EcommerceAlias } from '@Integrations/constants/ecommerce';
import { ConfigurationType } from '@packlink/packlink-sdk';
import { useTranslation } from '@packlink/translation-provider';

import { DiscogsIntegrationForm } from './DiscogsIntegrationForm';
import { DiscogsConfiguration, DiscogsIntegrationPanelContentProps, IDiscogsFormData } from './types';

export const DiscogsIntegrationPanel: FC<DiscogsIntegrationPanelContentProps> = (
    props: DiscogsIntegrationPanelContentProps,
): JSX.Element => {
    const { t } = useTranslation();
    const { onClose, integration, onDeactivate, onSubmit, isLoading, showError, clearError, onCancel } = props;

    const discogsConfiguration = integration.configuration;

    const defaultConfiguration: IDiscogsFormData = {
        token: discogsConfiguration?.data?.token || '',
    };

    const discogsOnSubmit = (formData: IDiscogsFormData): void => {
        const discogsConfiguration: DiscogsConfiguration = {
            identifier: integration.configuration?.identifier || '',
            integration: EcommerceAlias.Discogs,
            type: ConfigurationType.INBOUND,
            data: { ...formData },
        };

        const discogsIntegration = { ...integration, configuration: discogsConfiguration };
        onSubmit(discogsIntegration);
    };

    const validationSchema = yup.object().shape({
        token: yup.string().required(t('form.error.required', { field: t('integrations.discogs.token') })),
    });

    return (
        <Formik
            onSubmit={discogsOnSubmit}
            initialValues={defaultConfiguration}
            validationSchema={validationSchema}
            validateOnMount={true}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<IDiscogsFormData>): React.ReactNode => (
                <DiscogsIntegrationForm
                    onCancel={onCancel}
                    onClose={onClose}
                    formProps={formProps}
                    isLoading={isLoading}
                    integration={integration}
                    onDeactivate={onDeactivate}
                    showError={showError}
                    clearError={clearError}
                />
            )}
        </Formik>
    );
};
