import { DownloadCustomsInvoice } from '@packlink/customs-form';
import { ButtonSize, Link, WithCommonProps, useToast } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { HELP_CENTER_KEYS, useHelpCenter } from '@packlink/provider';

export type DownloadCustomsProps = WithCommonProps<{
    customsInvoiceId?: string;
}>;

export function DownloadCustoms({ customsInvoiceId, ...props }: DownloadCustomsProps): JSX.Element | null {
    const { t } = useTranslation();
    const toast = useToast(t);
    const getHelpPage = useHelpCenter();
    const customsSupportUrl = getHelpPage(HELP_CENTER_KEYS.customsInvoices);

    const onDownloadError = () => toast.error({ message: t('INTERNAL_SERVER_ERROR') });

    if (customsInvoiceId) {
        return (
            <DownloadCustomsInvoice
                customsInvoiceId={customsInvoiceId}
                size={ButtonSize.SMALL}
                onError={onDownloadError}
                {...props}
            >
                {t('post-sale.download.customs')}
            </DownloadCustomsInvoice>
        );
    } else {
        return (
            <Link
                href={customsSupportUrl}
                target="_blank"
                size={ButtonSize.SMALL}
                isExternal
                isButton
                isFullWidth
                {...props}
            >
                {t('post-sale.download.customs')}
            </Link>
        );
    }
}
