import { PropsWithChildren } from 'react';
import { PersonalizedExperienceProvider } from '@PersonalizedExperiences/providers/PersonalizedExperienceProvider';
import { PERSONALIZED_EXPERIENCE_CATEGORIES } from '@PersonalizedExperiences/Categories';
import { SubscriptionFeatureBehavioursProvider } from '@Subscriptions/providers/SubscriptionFeatureBehavioursProvider';
import { SentryAdditionalDataWrapper } from './SentryAdditionalDataProvider';

export function BulkAuthenticatedPacklinkProviders({ children }: PropsWithChildren<unknown>): JSX.Element {
    return (
        <SubscriptionFeatureBehavioursProvider>
            <SentryAdditionalDataWrapper>
                <PersonalizedExperienceProvider categories={PERSONALIZED_EXPERIENCE_CATEGORIES}>
                    {children}
                </PersonalizedExperienceProvider>
            </SentryAdditionalDataWrapper>
        </SubscriptionFeatureBehavioursProvider>
    );
}
