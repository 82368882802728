import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@shipengine/giger';
import { FormField } from '@shipengine/formik-giger';
import { useTranslation } from '@packlink/translation-provider';
import {
    FormikPhoneNumber,
    PhoneNumberValue,
    useCountryPrefixOptions,
    getPhonePrefixNumberValidation,
} from '@packlink/invoice-form';

import Packlink from '@sdk';
import { AppDispatch } from '@store';
import { getClientData } from '@store/selectors/client';
import { setClient } from '@store/actions/client';
import { SidePanelContent } from '@components/SidePanel/SidePanelContent';
import { SidePanelFooterActions } from '@components/SidePanel/SidePanelFooterActions';
import { SidePanelFooter } from '@components/SidePanel/SidePanelFooter';
import { getFormFieldStyles, getFormStyles } from './PhoneNumberConfirmationStyles';
import { useOtpVerification } from '@common/hooks/useOtpVerification';
import { OtpVerificationErrorApiResponse } from '@packlink/packlink-sdk/dist/lib/domains/otp-verification/Otp';
import { useAmplitude } from '@hooks';
import { AmplitudeEvents } from '@constants/amplitude';

const emptyPhoneNumberValue: PhoneNumberValue = {
    prefix: {
        alpha2Code: '',
        value: '',
        label: '',
    },
    number: '',
};

export type PhoneNumberConfirmationProps = {
    alpha2Code?: string;
    initialValue?: PhoneNumberValue | string;
    onBack: VoidFunction;
    onSuccess: (phone: PhoneNumberValue) => void;
    onError: (errorCode?: string, remainingTimeInMs?: number) => void;
};

type PhoneNumberConfirmationFormFields = {
    phone: PhoneNumberValue;
};

export function PhoneNumberConfirmation({
    alpha2Code,
    initialValue,
    onBack,
    onSuccess,
    onError,
}: PhoneNumberConfirmationProps): JSX.Element {
    const {
        t,
        i18n: { language: locale },
    } = useTranslation();
    const client = useSelector(getClientData);
    const dispatch = useDispatch<AppDispatch>();
    const { options: phonePrefixOptions } = useCountryPrefixOptions(config.cdn.baseUrl, alpha2Code, true);
    const { sendPhoneNumberMutation } = useOtpVerification();
    const { sendAmplitudeClickEvent } = useAmplitude();
    const initialValues = {
        phone: initialValue
            ? typeof initialValue === 'string'
                ? { number: initialValue.replace(/\D/g, ''), prefix: { value: '', label: '', alpha2Code: '' } }
                : initialValue
            : emptyPhoneNumberValue,
    };

    const validationSchema = yup.object().shape({
        phone: getPhonePrefixNumberValidation(t),
    });

    const submitForm = (
        values: PhoneNumberConfirmationFormFields,
        { resetForm }: FormikHelpers<PhoneNumberConfirmationFormFields>
    ) => {
        const { phone } = values;
        const phoneNumberValue = phone.prefix.value + phone.number;
        const mutationEvents = {
            onError: (error?: { response: { data: { messages: OtpVerificationErrorApiResponse[] } } }) => {
                const errorContent = error?.response?.data?.messages?.[0];
                onError(errorContent?.error_code, errorContent?.remaining_time_in_ms);
                resetForm();
            },
            onSuccess: () => {
                if (
                    typeof initialValue === 'string' ||
                    initialValue?.number !== phone.number ||
                    initialValue.prefix.value !== phone.prefix.value
                ) {
                    const updatedClient = client.clone();
                    updatedClient.phone = phoneNumberValue;

                    return Packlink.v1.clients.update(updatedClient).then(() => {
                        onSuccess(phone);
                        dispatch(setClient(updatedClient));
                    });
                } else {
                    onSuccess(phone);
                }
            },
        };
        sendAmplitudeClickEvent(AmplitudeEvents.SEND_CODE);
        sendPhoneNumberMutation(
            {
                phone_number: phoneNumberValue,
                locale,
                identifier: client.id as string,
            },
            mutationEvents
        );
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={submitForm}
                validationSchema={validationSchema}
                validateOnChange
                enableReinitialize
            >
                {({ isSubmitting }: FormikProps<PhoneNumberConfirmationFormFields>) => (
                    <Form css={getFormStyles}>
                        <SidePanelContent>
                            <Typography variant="body1" component="p">
                                {t('phone-verification.confirmation.title')}
                            </Typography>

                            <Typography variant="subtitle2" component="span">
                                {t('phone-verification.confirmation.phone-label')}
                            </Typography>

                            <FormField css={getFormFieldStyles} name="phone">
                                <Field
                                    name="phone"
                                    component={FormikPhoneNumber}
                                    prefixOptions={phonePrefixOptions}
                                    cdnUrl={config.cdn.baseUrl}
                                />
                            </FormField>
                        </SidePanelContent>

                        <SidePanelFooter>
                            <SidePanelFooterActions
                                actionButtonType="submit"
                                isLoading={isSubmitting}
                                onCancel={onBack}
                                actionText={t('phone-verification.confirmation.action')}
                                cancelText={t('shipment-panel.actions.back')}
                            />
                        </SidePanelFooter>
                    </Form>
                )}
            </Formik>
        </>
    );
}
