import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@packlink/translation-provider';
import { TFunction } from 'i18next';
import { Field, getIn, useField, useFormikContext } from 'formik';
import * as yup from 'yup';
import { Button, ButtonSize, ButtonVariant, IDropdownOption, SelectAutocomplete } from '@shipengine/giger';
import { IParcel, Parcel } from '@packlink/packlink-sdk';
import { customParcel } from '@utils/Parcel';
import { IParcelFieldErrors, IParcelFieldValues, ParcelField, ParcelFieldDistribution } from '@packlink/parcel-field';
import { getParcelFormStyles, getParcelsDeleteLinkStyles } from './ParcelFormStyles';
import { FormField, Input } from '@shipengine/formik-giger';
import { useParcels } from '@common/hooks/useParcels';

interface IParcelFormProps {
    name: string;
    index: number;
    onRemoveParcel?: (index: number) => void;
    parcelEdit?: boolean;
    isInSidebar?: boolean;
}

type PartialIParcel = IDropdownOption<Partial<IParcel> & { id: string; name: string }>;

export type IParcelForm = IParcelFieldValues;

export interface IParcelWithNameForm extends IParcelForm {
    parcelName: string;
}

const labelFields = (t: TFunction) => ({
    height: { field: t('form.label.height') },
    length: { field: t('form.label.length') },
    weight: { field: t('form.label.weight') },
    width: { field: t('form.label.width') },
});

const measuresValidations = (t: TFunction) => {
    const { height, weight, width, length } = labelFields(t);

    function fieldValidation({ field }: { field: string }) {
        return yup
            .string()
            .required(t('form.error.required', { field }))
            .test('integer', t('form.error.decimals-disallowed', { field }), (value?: string | null): boolean => {
                if (!value) {
                    return false;
                }
                return Number.isInteger(Number(value));
            })
            .test('positive', t('form.error.positive', { field }), (value?: string | null): boolean => {
                if (!value) {
                    return false;
                }
                return parseFloat(value) > 0;
            })
            .typeError(t('form.error.required', { field }));
    }

    return {
        height: fieldValidation(height),
        length: fieldValidation(length),
        weight: yup
            .string()
            .required(t('form.error.required', weight))
            .test(
                'decimals-limit',
                t('form.error.decimals-limit', { ...weight, limit: 3 }),
                (value?: string | null): boolean => {
                    const regEx = new RegExp(/[,.'](\d{4,})/);
                    return value ? !regEx.test(value) : false;
                },
            )
            .test('negative', t('form.error.positive', weight), (value?: string | null): boolean => {
                if (!value) {
                    return false;
                }
                return parseFloat(value.replace(/,|'/, '.')) > 0;
            }),
        width: fieldValidation(width),
    };
};

export const getParcelValidation = (t: TFunction): yup.ObjectSchema<IParcelForm | undefined> => {
    return yup.object<IParcelForm>().shape(measuresValidations(t));
};

export const getParcelWithNameValidation = (t: TFunction): yup.ObjectSchema<IParcelWithNameForm | undefined> => {
    const labelFields = {
        name: { field: t('form.label.parcel-name') },
    };

    return yup.object<IParcelWithNameForm>().shape({
        ...measuresValidations(t),
        name: yup
            .string()
            .typeError(t('form.error.required', labelFields.name))
            .required(t('form.error.required', labelFields.name))
            .max(150, t('form.error.max', { field: t('form.label.parcel-name'), number: 150 })),
    });
};

// TODO: https://packlink.atlassian.net/browse/FRON-606
export const ParcelForm: React.FC<IParcelFormProps> = (props: IParcelFormProps): JSX.Element => {
    const { name, index, onRemoveParcel, parcelEdit = false, isInSidebar = false } = props;
    const { t } = useTranslation();
    const [listField] = useField(name);
    const [field, meta, helper] = useField<PartialIParcel>(`${name}.${index}`);
    const { touched } = useFormikContext<Record<string, string>>();
    const touchedProperties = getIn(touched[name], `${index}`);
    const { parcels: definedParcels } = useParcels();

    const customParcelOption = useMemo(
        () => ({ ...customParcel, label: t(customParcel.name), value: customParcel.id }),
        [t],
    );

    const parcelSelectorOptions = useMemo<PartialIParcel[]>(
        () => [
            ...definedParcels.map((parcel: Parcel): PartialIParcel => {
                const parcelJSON = parcel.toJSON() as PartialIParcel;
                return {
                    ...parcelJSON,
                    label: `${parcelJSON.name} (${parcelJSON.weight}kg ${parcelJSON.length} x ${parcelJSON.width} x ${parcelJSON.height} cm)`,
                    value: parcelJSON.id,
                };
            }),
            { ...customParcelOption },
        ],
        [definedParcels, customParcelOption],
    );

    const getInitialParcel = (): PartialIParcel => {
        const defaultParcel = parcelSelectorOptions.find((parcel) => parcel.id === field.value.id);
        return defaultParcel || { ...customParcelOption };
    };

    const [selectedParcel, setSelectedParcel] = useState<PartialIParcel | null>(getInitialParcel);

    const selectDefinedParcel = useCallback(
        (_name: string, value: PartialIParcel | null): void => {
            const option = value ? value : customParcelOption;
            setSelectedParcel(option);
            helper.setValue(option);
        },
        [customParcelOption, helper],
    );

    const removeParcel = useCallback((): void => {
        onRemoveParcel && onRemoveParcel(index);
    }, [index, onRemoveParcel]);

    const getNormalizedParcel = (parcel: PartialIParcel): IParcelFieldValues => ({
        height: parcel.height ? parcel.height.toString() : '',
        length: parcel.length ? parcel.length.toString() : '',
        weight: parcel.weight ? parcel.weight.toString() : '',
        width: parcel.width ? parcel.width.toString() : '',
    });

    const handleParcelFieldChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
            const {
                target: { name: fieldName, value },
            } = e;
            const editedProp = fieldName.substring(fieldName.lastIndexOf('.') + 1);
            const parsedValue = (value || '').replace(/,|'/, '.');
            const isName = editedProp === 'name';

            /* In edition mode we need to avoid all logic relative to find current parcel etc
             * In select mode we cant edit name and id.
             */
            const id = parcelEdit ? field.value.id : customParcelOption.id;
            const name = parcelEdit ? field.value.name : customParcelOption.name;
            const label = parcelEdit ? field.value.name : customParcelOption.label;
            const newCustomParcel = {
                ...field.value,
                id,
                name,
                value: id,
                label,
                [editedProp]: isName ? value : parsedValue,
            };

            setSelectedParcel(newCustomParcel);
            helper.setValue(newCustomParcel);

            // Changing parcel's id rerenders ParcelField component fully,
            // as it's kind of a new reference for Formik. This causes the
            // field to lose focus, so we try to focus it again through this
            if (!parcelEdit) {
                // Dummy hack to wait for Formik new values to be applied
                // Pending https://github.com/jaredpalmer/formik/issues/529
                await Promise.resolve();

                // Can't use event target element, or a ref, since the field
                // we are trying to focus on is a newly rendered element
                const editedField = document.getElementById(`${fieldName}`);
                editedField?.focus();
            }
        },
        [parcelEdit, field.value, customParcelOption, helper],
    );

    return (
        <section css={getParcelFormStyles}>
            <section>
                {parcelEdit ? (
                    <FormField name={`${name}[0].name`}>
                        <Field
                            component={Input}
                            name={`${name}[0].name`}
                            label={t('form.label.parcel-name')}
                            value={field.value.name}
                            onChange={handleParcelFieldChange}
                            touched={touchedProperties}
                            onBlur={field.onBlur}
                        />
                    </FormField>
                ) : (
                    <FormField name={`defaultParcel${index}`}>
                        <SelectAutocomplete
                            name={`defaultParcel${index}`}
                            options={parcelSelectorOptions}
                            value={selectedParcel}
                            label={t('form.label.parcel')}
                            onChange={selectDefinedParcel}
                            noResultsLabel={t('autocomplete.messages.no-results-found')}
                        />
                    </FormField>
                )}
                <ParcelField
                    name={`${name}.${index}`}
                    values={getNormalizedParcel(field.value)}
                    errors={meta.error as unknown as IParcelFieldErrors}
                    touched={touchedProperties}
                    onChange={handleParcelFieldChange}
                    onBlur={field.onBlur}
                    distribution={isInSidebar ? ParcelFieldDistribution.TWO_LINES : ParcelFieldDistribution.ONE_LINE}
                />
                {listField.value.length > 1 && (
                    <Button
                        variant={ButtonVariant.TEXT}
                        size={ButtonSize.LARGE}
                        onClick={removeParcel}
                        css={getParcelsDeleteLinkStyles}
                    >
                        {t('form.parcel.delete-parcel')}
                    </Button>
                )}
            </section>
        </section>
    );
};
