import { ChangeEvent, PropsWithChildren } from 'react';

import { AmplitudeEvents, AmplitudeProperties, FLOW_AMPLITUDE_PROPERTY } from '@constants/amplitude';
import { useAmplitude } from '@hooks';
import { Divider, Switch, Typography } from '@shipengine/giger';
import { useTranslation } from '@packlink/translation-provider';
import { toKebabCase } from '@utils/toKebabCase';
import { camelToSnakeUpperCase } from '@utils/camelToSnakeUpperCase';

import {
    getSwitchCardDescriptionStyles,
    getSwitchCardDividerStyles,
    getSwitchCardHeaderStyles,
    getSwitchCardStyles,
    getSwitchCardTitleStyles,
    getSwitchStyles,
} from './SwitchCardStyles';

export type SwitchCardProps = {
    name: 'cashOnDelivery' | 'proofOfDelivery' | 'adultSignature' | 'additionalHandling';
    checked?: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    showDescription?: boolean;
    disabled?: boolean;
};

export const SwitchCard = (props: PropsWithChildren<SwitchCardProps>): JSX.Element => {
    const { name, checked = false, handleChange, showDescription = true, children, disabled = false } = props;
    const { sendAmplitudeClickEvent } = useAmplitude();
    const { t } = useTranslation();
    const kebabCaseName = toKebabCase(name);

    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        const {
            target: { checked },
        } = event;
        sendAmplitudeClickEvent(AmplitudeEvents[camelToSnakeUpperCase(name)], {
            [AmplitudeProperties.SELECTED]: checked,
            [AmplitudeProperties.FLOW]: FLOW_AMPLITUDE_PROPERTY.MANUAL_CHECKOUT,
        });
        handleChange(event);
    };

    return (
        <section css={getSwitchCardStyles(checked)}>
            <header css={getSwitchCardHeaderStyles}>
                <Typography variant="body2" bold css={getSwitchCardTitleStyles}>
                    {t(`${kebabCaseName}.card.title`)}
                </Typography>

                <Switch
                    value={`switch-${kebabCaseName}-field-value`}
                    name={name}
                    isChecked={checked}
                    onChange={onChangeHandler}
                    css={getSwitchStyles}
                    disabled={disabled}
                >
                    {checked ? t(`${kebabCaseName}.card.selected`) : t(`${kebabCaseName}.card.deselected`)}
                </Switch>
            </header>
            {(showDescription || checked) && <Divider css={getSwitchCardDividerStyles} />}
            {showDescription && (
                <Typography component="p" variant="small" css={getSwitchCardDescriptionStyles}>
                    {t(`${kebabCaseName}.card.description`)}
                </Typography>
            )}
            {children}
        </section>
    );
};
