import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from '@packlink/translation-provider';
import { useUnmount } from '@packlink/utils';
import { Tab, TabList, Tag, TagColor } from '@shipengine/giger';
import { IShippingRule } from '@packlink/packlink-sdk';
import { SettingsPage } from '@components/SettingsPage/SettingsPage';
import { SettingsPageHeader } from '@components/SettingsPage/SettingsPageHeader';
import { AmplitudeEvents } from '@constants/amplitude';
import { ShippingRulesProvider } from './context/shippingRules';
import { RulesFormTab } from './tabs/RulesFormTab/RulesFormTab';
import { RulesListTab } from './tabs/RulesListTab/RulesListTab';
import { QueryKeys } from './tabs/RulesFormTab/types/queryKeys';
import { pageStyles, tabStyles, tagStyles } from './ShippingRulesSettingsStyles';
import { HowItWorksTab } from '@ShippingRules/pages/ShippingRulesSettings/tabs/HowItWorksTab/HowItWorksTab';
import { useAmplitude } from '@hooks/useAmplitude';

enum ShippingRulesTab {
    RULES_LIST,
    RULES_FORM,
    HOW_IT_WORKS,
}

export function ShippingRulesSettings(): JSX.Element {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(ShippingRulesTab.RULES_LIST);
    const [selectedRule, setSelectedRule] = useState<IShippingRule>();
    const queryClient = useQueryClient();
    const { sendAmplitudeClickEvent } = useAmplitude();

    const onClickTab = (tab: ShippingRulesTab, event: AmplitudeEvents) => {
        tab === ShippingRulesTab.RULES_LIST && setSelectedRule(undefined);
        setSelectedTab(tab);
        sendAmplitudeClickEvent(event);
    };

    const onEditRule = (rule?: IShippingRule) => {
        setSelectedRule(rule);
        setSelectedTab(ShippingRulesTab.RULES_FORM);
    };

    const onCancelEdit = () => {
        setSelectedTab(ShippingRulesTab.RULES_LIST);
        setSelectedRule(undefined);
    };

    const onEditSuccess = () => {
        setSelectedTab(ShippingRulesTab.RULES_LIST);
        setSelectedRule(undefined);
    };

    useUnmount(() => {
        queryClient.invalidateQueries(QueryKeys.SHIPPING_RULES);
    });

    return (
        <ShippingRulesProvider>
            <SettingsPage css={pageStyles}>
                <SettingsPageHeader>{t('shipping-rules.settings.title')}</SettingsPageHeader>

                <TabList css={tabStyles}>
                    <Tab
                        selected={selectedTab === ShippingRulesTab.RULES_LIST}
                        onClick={() => onClickTab(ShippingRulesTab.RULES_LIST, AmplitudeEvents.MY_SHIPPING_RULES)}
                        data-id="my-shipping-rules-tab"
                    >
                        {t('shipping-rules.settings.my-rules')}
                    </Tab>
                    <Tab
                        selected={selectedTab === ShippingRulesTab.RULES_FORM}
                        onClick={() => onClickTab(ShippingRulesTab.RULES_FORM, AmplitudeEvents.SHIPPING_RULES_NEW_RULE)}
                        data-id="new-shipping-rules-tab"
                    >
                        {t('shipping-rules.settings.add-new-rules')}
                    </Tab>
                    <Tab
                        selected={selectedTab === ShippingRulesTab.HOW_IT_WORKS}
                        onClick={() =>
                            onClickTab(ShippingRulesTab.HOW_IT_WORKS, AmplitudeEvents.SHIPPING_RULES_HOW_IT_WORKS_TAB)
                        }
                        data-id="shipping-rules-how-it-works-tab"
                    >
                        {t('shipping-rules.settings.how-it-works')}
                        <Tag color={TagColor.SUCCESS} css={tagStyles}>
                            {t('settings.menu.new-tag')}
                        </Tag>
                    </Tab>
                </TabList>

                {selectedTab === ShippingRulesTab.RULES_LIST && <RulesListTab onEditRule={onEditRule} />}
                {selectedTab === ShippingRulesTab.RULES_FORM && (
                    <RulesFormTab selectedRule={selectedRule} onCancel={onCancelEdit} onSuccess={onEditSuccess} />
                )}
                {selectedTab === ShippingRulesTab.HOW_IT_WORKS && <HowItWorksTab />}
            </SettingsPage>
        </ShippingRulesProvider>
    );
}
